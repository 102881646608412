const COLOR_SAMPLES = [
    [71, 0, 216],
    [153, 0, 240],
    [249, 0, 191],
    [255, 133, 179],
    [182, 255, 206],
    [246, 255, 164],
    [253, 215, 170],
    [255, 168, 168],
    [255, 84, 3],
    [255, 248, 154],
    [255, 201, 0],
    [8, 110, 125],
    [26, 95, 122]
];

export function toRGBString(color) {
    return `rgb(${color[0]},${color[1]},${color[2]})`;
}

export function getSampleColors() {
    return COLOR_SAMPLES.map((color) => toRGBString(color));
}

// Copied over from the backend code

const redBits = 63 << 12;
const greenBits = 63 << 6;
const blueBits = 63;

export function toneCodeToRGBColor(toneCode) {
    const colorId = parseInt(toneCode);
    const red = (colorId & redBits) >> 12;
    const green = (colorId & greenBits) >> 6;
    const blue = colorId & blueBits;
    return [red * 4, green * 4, blue * 4];
}

function RGBToHSL(r, g, b) {
    const rf = r / 255;
    const gf = g / 255;
    const bf = b / 255;

    const cmin = Math.min(rf, gf, bf);
    const cmax = Math.max(rf, gf, bf);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    if (delta === 0) {
        h = 0;
    } else if (cmax === rf) {
        h = ((gf - bf) / delta) % 6;
    } else if (cmax === gf) {
        h = (bf - rf) / delta + 2;
    } else {
        h = (rf - gf) / delta + 4;
    }

    h = Math.round(h * 60);
    if (h < 0) {
        h += 360;
    }
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    l = parseInt(Math.abs(l * 100));
    s = parseInt(Math.abs(s * 100));

    return [h, s, l];
}

export function toneCodeToHSLColor(toneCode) {
    const rgb = toneCodeToRGBColor(toneCode);
    return RGBToHSL(...rgb);
}
