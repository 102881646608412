import React, { useMemo } from 'react';

import { Tile } from './Tile';
import iconDefeat from '../assets/icon_defeat.svg';

import './GameReward.scss';

function getTextColor(total, rank) {
    const neon = [128, 255, 219];
    const diff = neon.map((d) => 255 - d);
    const ratio = rank / total;
    return `rgb(${diff.map((d, i) => Math.floor(d * ratio) + neon[i]).join()})`;
}

const TABLE_WIDTH = 110;
const POPUP_WIDTH = 180;

export function GameReward({
    percent, colored, classNum, rank, defeat, flag, totalPlayer, knockouts, allocatedWidth
}) {
    const showPopups = useMemo(() => (allocatedWidth >= TABLE_WIDTH + POPUP_WIDTH), [allocatedWidth]);
    const knockoutBonus = useMemo(() => {
        if (!knockouts) {
            return 0;
        }
        let totalBonus = 0;
        knockouts.forEach((ko) => {
            if (ko.level) {
                totalBonus += ko.level;
            }
        });
        return totalBonus;
    }, [knockouts]);
    return (
        <div className="game-reward" style={{ width: `${TABLE_WIDTH}px` }}>
            <div className="title">Reward</div>
            <div className="class-num row">
                <span className="label">Class</span>
                <span className="value" style={{ color: getTextColor(2, 2 - classNum) }}>{classNum}</span>
            </div>
            <div className="colored row">
                <span className="label">Colored Cells</span>
                <span className={`value class-${classNum}`}>{colored}</span>
            </div>
            <div className="my-share row">
                <span className="label">My Share</span>
                <span className="value">{percent}%</span>
            </div>
            <div className="row tones">
                <div className={`expected-tones class-${classNum}`}>{parseFloat(colored * Math.max(classNum, 1) * percent / 100).toFixed(2)}{knockoutBonus ? ` + ${knockoutBonus}` : ''}</div>
                <div className="label">Tones</div>
            </div>
            {showPopups && (<div className="explanation-box">
                <Tile>
                    <div className="explanation-inner">
                        <div className="rank row">
                            <span className="label">Rank</span>
                            <span className="value" style={{ color: getTextColor(totalPlayer, rank) }}>{rank}</span>
                        </div>
                        <div className="defeat row">
                            <span className="label"><img src={iconDefeat} alt="defeat" /></span>
                            <span className="value" style={{ color: getTextColor(totalPlayer, totalPlayer - defeat) }}>{defeat}</span>
                        </div>
                        <div className="flag row">
                            <span className="label"><i className="bi bi-flag-fill" /></span>
                            <span className="value">{flag}</span>
                        </div>
                    </div>
                </Tile>
            </div>)}
        </div>
    );
}
