import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './DarkBackground.scss';

const MAX_SQUARES = 1000;

function getRandomSquareData() {
    // Weighted more on the right hand side
    const xPos = (100 - parseInt(Math.random() * 21.5) ** 1.5);
    const yPos = parseInt(Math.random() * 10);
    const pattern = parseInt(Math.random() * 3);

    return { xPos, yPos, pattern };
}

export function DarkBackground({ gradation, squares }) {
    const [squareData, setSquareData] = useState([
        { idx: -1, ...getRandomSquareData() },
        { idx: -2, ...getRandomSquareData() },
        { idx: -3, ...getRandomSquareData() },
        { idx: -4, ...getRandomSquareData() },
        { idx: -5, ...getRandomSquareData() }
    ]);
    useEffect(() => {
        setInterval(() => {
            const shouldAddSquare = (Math.random() > 0.5);
            if (!shouldAddSquare) {
                return;
            }
            setSquareData((old) => {
                if (old.length > MAX_SQUARES) {
                    return old;
                }
                return [...old, {
                    idx: old.length, ...getRandomSquareData()
                }];
            });
        }, 1000);
    }, [setSquareData]);
    return (
        <div className="dark-background">
            {gradation && <div className="gradation" />}
            {squares && (
                <div className="squares">
                    {squareData.map((sd) => (
                        <span key={sd.idx} className={`square pattern-${sd.pattern}`} style={{ left: `${sd.xPos}%`, transform: `translateY(-${sd.yPos}%)` }} />
                    ))}
                </div>
            )}
        </div>
    );
}

DarkBackground.propTypes = {
    gradation: PropTypes.bool,
    squares: PropTypes.bool
};
