export function shortenPlayerTag(fullTag) {
    if (!fullTag) {
        return '';
    }
    if (fullTag.length <= 2) {
        return fullTag;
    }

    const SEPARATORS = ['-', '_', ' '];
    for (let i = 0; i < SEPARATORS.length; i++) {
        const splitted = fullTag.split(SEPARATORS[i]);
        if (splitted && splitted.length >= 2) {
            const shorten = splitted[0].substring(0, 1) + splitted[1].substring(0, 1);
            return shorten.toUpperCase();
        }
    }

    return fullTag.substring(0, 2).toUpperCase();
}

export function toReactKey(str) {
    let key = str;
    const TO_REMOVE = ['!', '?', '/', '*', '.', ',', ' '];
    TO_REMOVE.forEach((toRemove) => {
        key = key.replace(toRemove, '');
    });
    return key;
}

export function toOrdinalNumber(num) {
    const secondPlace = num % 10;
    const thirdPlace = num % 100;
    let suffix = 'th';
    if (secondPlace === 1 && thirdPlace !== 11) {
        suffix = 'st';
    }
    if (secondPlace === 2 && thirdPlace !== 12) {
        suffix = 'nd';
    }
    if (secondPlace === 3 && thirdPlace !== 13) {
        suffix = 'rd';
    }
    return `${num}${suffix}`;
}
