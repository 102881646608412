export function toPercentage(value) {
    const percentValue = (parseFloat(value) * 100).toFixed(2);
    return `${percentValue}%`;
}

export function toFixedTwo(value) {
    return parseFloat(value).toFixed(2);
}

export function toFixedThree(value) {
    return parseFloat(value).toFixed(3);
}

export function noopFormatter(value) {
    return value;
}

export const Stats = {
    ShapeComplexity: { stat: 'shape_complexity', displayName: 'Complexity', formatter: toFixedTwo },
    ShapeSymmetry: { stat: 'shape_symmetry', displayName: 'Symmetry', formatter: toPercentage },
    SurfaceCoverage: { stat: 'surface_coverage', displayName: 'Coverage', formatter: toPercentage },
    ColorVariety: { stat: 'hv', displayName: 'Colors', formatter: toFixedTwo },
    LeaderScore: { stat: 'winners_score', displayName: 'Top Score', formatter: noopFormatter },
    GameLength: { stat: 'game_length', displayName: 'Turns', formatter: noopFormatter },
    AverageSaturation: { stat: 'sm', displayName: 'Saturation', formatter: toPercentage },
    AverageLightness: { stat: 'lm', displayName: 'Brightness', formatter: toFixedTwo }
};

export const StatDisplays = {
    shape_complexity: {
        name: 'shape_complexity', displayName: 'Complexity', formatter: toFixedTwo, description: 'Create complex yet symmetric outline'
    },
    shape_symmetry: {
        name: 'shape_symmetry', displayName: 'Symmetry', formatter: toPercentage, description: 'Create complex yet symmetric outline'
    },
    surface_coverage: {
        name: 'surface_coverage',
        displayName: 'Coverage',
        formatter: toPercentage,
        description: 'Create complex yet symmetric outline',
        missionBodyTextTemplate: {
            upper: {
                accomplished: 'You did it! You have made coverage above {{threshold}}!',
                notAccomplished: 'Increase coverage above {{threshold}}!'
            },
            lower: {
                accomplished: 'Keep coverage below {{threshold}}!',
                notAccomplished: 'You could not keep coverage below {{threshold}}.'
            }
        }
    },
    hv: {
        name: 'hv',
        displayName: 'Color Diversity',
        formatter: toFixedTwo,
        description: 'Create various colors'
    },
    sm: {
        name: 'sm', displayName: 'Saturation', formatter: toPercentage, description: 'Create complex yet symmetric outline'
    },
    lm: {
        name: 'lm',
        displayName: 'Brightness',
        formatter: toFixedTwo,
        description: 'Create complex yet symmetric outline'
    },
    winners_average_emission: {
        name: 'winners_average_emission', displayName: '(L) Avg Emission', formatter: toFixedTwo, description: 'Create complex yet symmetric outline'
    },
    winners_total_emission: {
        name: 'winners_total_emission', displayName: 'Top Emission', formatter: noopFormatter, description: 'Create complex yet symmetric outline'
    },
    winners_score: {
        name: 'winners_score', displayName: 'Top Score', formatter: noopFormatter, description: 'Create complex yet symmetric outline'
    },
    game_length: {
        name: 'game_length',
        displayName: 'Turns',
        formatter: noopFormatter,
        description: 'Create complex yet symmetric outline',
        missionBodyTextTemplate: {
            upper: {
                accomplished: 'You did it! You have made the game longer than {{threshold}} turns!',
                notAccomplished: 'Make the game longer than {{threshold}} turns!'
            },
            lower: {
                accomplished: 'Finish the game before turn {{threshold}}!',
                notAccomplished: 'You could not finish the game before turn {{threshold}}.'
            }
        }
    }
};

export function pickObjectiveStats(gameStats, objectiveStatNames) {
    const stats = {};
    if (objectiveStatNames) {
        objectiveStatNames.forEach((statName) => {
            stats[statName] = gameStats[statName];
        });
    }
    return stats;
}
