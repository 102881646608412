import React, { useState, useCallback, useMemo, useEffect } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

import {
    PASSWORD_PATTERN,
    PASSWORD_RESTRICTION_MESSAGE,
} from '../utils/user';
import { Button } from './Button';
import { TextField } from './TextField';
import logo from '../assets/logo.svg';
import { Tile } from './Tile';

import './Register.scss';

export function ResetPassword({ recoveryCode, onFinish }) {
    const [playerTag, setPlayerTag] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [message, setMessage] = useState('');

    const onSubmit = useCallback(() => {
        if (!isPasswordValid) {
            return;
        }
        // TODO: playerTag validation as validation rules finalized
        axios.post(`${process.env.REACT_APP_GAME_REST_URI}/admin/password-recovery/${recoveryCode}`, {
            playerTag,
            newPassword: password
        }).then(() => {
            if (onFinish) {
                onFinish();
            }
        }).catch(() => {
            setMessage('Password reset failed.');
        });
    }, [recoveryCode, playerTag, password, isPasswordValid, onFinish]);
    const onChangePlayerTag = useCallback((e) => {
        const newPlayerTag = e.target.value;
        setPlayerTag(newPlayerTag);
    }, [setPlayerTag]);
    const onChangePassword = useCallback((e) => {
        const newPassword = e.target.value;
        if (!newPassword.match(PASSWORD_PATTERN)) {
            setIsPasswordValid(false);
        } else {
            setIsPasswordValid(true);
        }
        setPassword(newPassword);
    }, [setPassword]);
    const buttonDisableMessage = useMemo(() => {
        const messages = [];
        if (!password) {
            if (messages.length > 0) {
                messages.push(<br />);
            }
            messages.push('Please enter password');
        } else if (!isPasswordValid) {
            if (messages.length > 0) {
                messages.push(<br />);
            }
            messages.push(PASSWORD_RESTRICTION_MESSAGE);
        }
        return messages;
    }, [playerTag, password, isPasswordValid]);
    // /game is currently the only page that guest should go after bypassing sign-in
    return (
        <Tile>
            <div className="register-box">
                <div className="heading">
                    <img src={logo} alt="" />
                    <div className="gamename">blend</div>
                    <div className="subtitle">Private Beta</div>
                </div>
                <div className="form">
                    <TextField label="Player Tag" id="player-tag" type="text" maxLength={16} value={playerTag} onChange={onChangePlayerTag} />

                    <TextField label="New Password" id="password" type="password" maxLength={16} value={password} onChange={onChangePassword} />
                    {message && (
                        <div className="message">{message}</div>
                    )}
                </div>
                <div className="actions">
                    <Button label="Reset Password" contentType="default" onClick={onSubmit} transparent disabled={!isPasswordValid} disableMessage={buttonDisableMessage} />
                </div>
            </div>
        </Tile>
    );
}
