import React from 'react';

import { StatDisplays } from '../utils/stats';
import { DistributionChart } from './DistributionChart';

import './GameStat.scss';

export function isInRange(stat) {
    return (stat.value < stat.p10 || stat.value > stat.p90);
}

export function GameStat({ statName, stat }) {
    return (
        <div key={`stat-${statName}`} className="game-stat">
            <div className="display-name">{StatDisplays[statName].displayName}</div>
            {stat.buckets && <DistributionChart width={180} height={30} currentValue={stat.value} buckets={stat.buckets} formatter={StatDisplays[statName].formatter} range={{ p10: stat.p10, p90: stat.p90 }} />}
            <div className={`stat-value${isInRange(stat) ? ' in-range' : ''}`}>{StatDisplays[statName].formatter(stat.value)}</div>
        </div>
    );
}
