import React from 'react';

import './LoadingGrid.scss';

export function LoadingGrid() {
    return (
        <div className="loading-grid">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
}
