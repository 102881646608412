export function trim2DArray(arr, fromX, fromY, toX, toY) {
    const trimmed = [];
    for (let j = fromY; j <= toY; j += 1) {
        trimmed.push([]);
        for (let i = fromX; i <= toX; i += 1) {
            trimmed[j - fromY].push(arr[j][i]);
        }
    }
    return trimmed;
}
