import React, { createContext, useMemo } from 'react';
import { sum } from 'lodash';

import { classToFactor } from '../utils/game';
import { accomplishedCountToClass, getAccomplishedCount } from '../utils/mission';

function getRewardObject(players, me, objectiveStats, cells) {
    const cellCoveredStatus = cells.flatMap((row) => row.map((cell) => (cell.color && cell.color.depth > 0 ? 1 : 0)));
    const cellCount = sum(cellCoveredStatus);
    const achievedCount = getAccomplishedCount(objectiveStats);
    const gameClass = accomplishedCountToClass(achievedCount);
    const factor = classToFactor(gameClass);
    const myPoint = me.score + me.bonus;
    const totalPoint = sum(players.map((player) => (player.score + player.bonus)));
    return {
        total: {
            base: {
                cells: cellCount,
                base: cellCount
            },
            factor: {
                class: gameClass,
                factor
            },
            total: cellCount * factor
        },
        share: {
            myPoint: {
                myPoint,
                defeated: me.knockedOut,
                flagged: me.flagged,
                ranking: me.ranking,
                bonus: me.bonus
            },
            totalPoint,
            share: myPoint / totalPoint
        }
    };
}

export const GameplayContext = createContext();

export function GameplayDataProvider({
    gameState, missions, cells, viewport, players, playerProfiles, playerPlayData, statusMessage, eventMessageQueue, turn, startTutorial, stopTutorial, children
}) {
    const me = useMemo(() => players.find((player) => player.isMe), [players]);
    const rewardInfo = useMemo(() => {
        if (!players || !me || !missions || !cells) {
            return null;
        }
        return getRewardObject(players, me, missions, cells);
    }, [players, me, missions, cells]);

    return (
        <GameplayContext.Provider value={{
            gameState, cells, viewport, players, playerProfiles, playerPlayData, me, rewardInfo, missions, statusMessage, eventMessageQueue, turn, startTutorial, stopTutorial
        }}
        >
            {children}
        </GameplayContext.Provider>
    );
}
