import React, { useMemo, useState, useEffect } from 'react';
import {
    Stage, Layer, Shape, Line
} from 'react-konva';

import { traceBorder, directionToDiffSigns } from '../utils/gameBoard';
import { toRGBString } from '../utils/color';
import { trim2DArray } from '../utils/array';

import './Board.scss';

// Cell consts
const PADDING = 24;
const GUTTER_SIZE = 4;
const BORDER_COLOR = '#CBCBCB';
const BORDER_WIDTH = 1;
const OUT_OF_SIGHT_OPACITY = 0.3;
const CLOSED_OPACITY = 0.4;
const CELL_PADDING = 12;
const INNER_PADDING_RATIO = 0.03;
const BLENDING_RECT_THICKNESS = 0.09;

// Marker consts
const MIN_MARKER_SCALE = 0.3;
const MY_POINTER_UPPER_MARGIN = 8;
const MIN_MARKER_MAX = 20;

export function Board({
    boardWidth, boardHeight, cells, players, gameClass, entering, playerPlayData, origin, viewport
}) {
    const hCells = viewport ? viewport.to.x - viewport.from.x + 1 : cells[0].length;
    const vCells = viewport ? viewport.to.y - viewport.from.y + 1 : cells.length;
    const cellWidth = useMemo(() => (boardWidth - ((hCells - 1) * GUTTER_SIZE) - (PADDING * 2)) / hCells, [boardWidth, hCells]);
    const cellHeight = useMemo(() => (boardHeight - ((vCells - 1) * GUTTER_SIZE) - (PADDING * 2)) / vCells, [boardHeight, vCells]);

    const viewportedCells = useMemo(() => {
        if (!viewport) {
            return cells;
        }
        return trim2DArray(cells, viewport.from.x, viewport.from.y, viewport.to.x, viewport.to.y);
    }, [viewport, cells]);

    return (
        <div className="new-board" style={{ width: `${boardWidth}px`, height: `${boardHeight}px` }}>
            <Cells cells={cells} width={cellWidth} height={cellHeight} hCells={hCells} vCells={vCells} origin={origin || { x: 0, y: 0 }} />
            {players && <PlayerMarkers players={players} cellWidth={cellWidth} cellHeight={cellHeight} entering={entering} playerPlayData={playerPlayData} />}
            <BoardOverlay boardWidth={boardWidth} boardHeight={boardHeight} cellWidth={cellWidth} cellHeight={cellHeight} cells={viewportedCells} players={players} />
            <div className={`outer-border-wrapper class${gameClass}`}>
                <div className="board-glow" />
                <div className="outer-border" />
            </div>
        </div>
    );
}

function BoardOverlay({
    boardWidth, boardHeight, cellWidth, cellHeight, cells, players
}) {
    const me = useMemo(() => (players ? players.find((player) => player.isMe) : null), [players]);

    const { baseLeft: myBaseLeft, baseTop: myBaseTop } = me ? getBasePosition(me.x, me.y, cellWidth, cellHeight) : { baseLeft: null, baseTop: null };
    return (
        <div className="board-overlay">
            <Stage width={boardWidth} height={boardHeight}>
                {me && me.isAlive && (
                    <Layer>
                        <Shape
                            key={`player-pointer-${me.name}`}
                            x={0}
                            y={0}
                            fill={toRGBString(me.color)}
                            stroke={toRGBString(me.color)}
                            strokeWidth={2}
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo((boardWidth / 2), boardHeight + MY_POINTER_UPPER_MARGIN + PADDING);
                                context.lineTo(myBaseLeft + (cellWidth / 2), myBaseTop + (cellHeight / 2));
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                        />
                    </Layer>
                )}
                <Layer>
                    <Border cells={cells} cellWidth={cellWidth} />
                </Layer>
            </Stage>
        </div>
    );
}



function Border({
    cells, cellWidth
}) {
    if (!cells) {
        return null;
    }
    const closed = useMemo(() => pluckClosed(cells), [cells]);
    const borderPoints = traceBorder(closed, cellWidth, GUTTER_SIZE, BORDER_WIDTH);
    if (borderPoints.length === 0) {
        return null;
    }
    return (
        <Line
            points={borderPoints.map(({ x, y }) => [x + PADDING - (BORDER_WIDTH * 2), y + PADDING - (BORDER_WIDTH * 2)]).flat()}
            stroke={BORDER_COLOR}
            strokeWidth={BORDER_WIDTH}
            closed
        />
    );
}

function getMarkerBorderColor(colorMode) {
    if (colorMode === 'dark') {
        return '#EAEAEA';
    }
    return '#2D2D2D';
}

function getBorderWidth(markerWidth) {
    if (markerWidth < 30) {
        return 1;
    }
    return 2;
}

function PlayerMarkers({
    players, cellWidth, cellHeight, entering, playerPlayData
}) {
    const [playerMarkers, setPlayerMarkers] = useState([]);

    useEffect(() => {
        const renderIterationKey = parseInt(Math.random() * 1000);

        const interstitialMarkers = players.filter((player) => (player.isAlive || player.justEliminated)).filter((player) => (typeof (player.prevX) === 'number' && typeof (player.prevY) === 'number'))
            .map((player) => {
                const { baseLeft, baseTop } = getBasePosition(player.prevX, player.prevY, cellWidth, cellHeight);
                const markerScale = (MIN_MARKER_SCALE + (player.size * (1 - MIN_MARKER_SCALE)));
                const width = cellWidth * markerScale;
                const height = cellHeight * markerScale;
                const markerLeft = baseLeft + ((cellWidth - width) / 2);
                const markerTop = baseTop + ((cellHeight - height) / 2);
                const markerInner = (player.drawnCardNum && !player.isMe)
                    ? <div key={`card-bubble-${player.name}-${renderIterationKey}`} className="card-bubble">{player.drawnCardNum}</div>
                    : null;
                const classNames = ['player-marker'];
                if (entering) {
                    classNames.push('entering');
                }
                if (player.isMe) {
                    classNames.push('me');
                }
                if (!player.isAlive) {
                    classNames.push('eliminated');
                }
                return (
                    <div
                        key={`player-marker-${player.name}`}
                        className={classNames.join(' ')}
                        style={{
                            left: markerLeft, top: markerTop, width, height, backgroundColor: toRGBString(player.color)
                        }}
                    >
                        <div className="player-marker-inner">
                            <div className="marker-border" style={{ borderColor: getMarkerBorderColor(player.colorMode), borderWidth: `${getBorderWidth(width)}px` }} />
                            {markerInner}
                        </div>
                    </div>
                );
            });
        if (interstitialMarkers.length > 0) {
            setPlayerMarkers(interstitialMarkers);
        }
        setTimeout(() => {
            const markers = players.filter((player) => (player.isAlive || player.justEliminated)).filter((player) => (player.x !== null && player.y !== null)).map((player) => {
                let { x } = player;
                let { y } = player;
                if (!player.isAlive && player.prevX !== null && player.prevY !== null) {
                    x = player.prevX;
                    y = player.prevY;
                }
                const { baseLeft, baseTop } = getBasePosition(x, y, cellWidth, cellHeight);
                const markerScale = !player.justEliminated ? (MIN_MARKER_SCALE + (player.size * (1 - MIN_MARKER_SCALE))) : MIN_MARKER_SCALE;
                const width = Math.max(cellWidth - CELL_PADDING, MIN_MARKER_MAX) * markerScale;
                const height = Math.max(cellHeight - CELL_PADDING, MIN_MARKER_MAX) * markerScale;
                const markerLeft = baseLeft + ((cellWidth - width) / 2);
                const markerTop = baseTop + ((cellHeight - height) / 2);
                const markerInner = (player.drawnCardNum && !player.isMe)
                    ? <div key={`card-bubble-${player.name}-${renderIterationKey}`} className="card-bubble">{player.drawnCardNum}</div>
                    : null;
                const classNames = ['player-marker'];
                if (entering) {
                    classNames.push('entering');
                }
                if (player.isMe) {
                    classNames.push('me');
                }
                if (player.justEliminated) {
                    classNames.push('eliminated');
                }
                return (
                    <div
                        key={`player-marker-${player.name}`}
                        className={classNames.join(' ')}
                        style={{
                            left: markerLeft, top: markerTop, width, height, backgroundColor: toRGBString(player.color)
                        }}
                    >
                        <div className="player-marker-inner">
                            <div className="marker-border" style={{ borderColor: getMarkerBorderColor(player.colorMode), borderWidth: `${getBorderWidth(width)}px` }} />
                            {markerInner}
                        </div>
                    </div>
                );
            });
            setPlayerMarkers(markers);
        }, 0);
    }, [players, cellWidth, cellHeight]);

    const moveDirectionIndicator = useMemo(() => {
        const me = players.find((player) => (player.isAlive && player.isMe));
        const myDirection = playerPlayData?.nextMove?.direction;
        if (!entering && me && myDirection) {
            let { x } = me;
            let { y } = me;
            const diffSigns = directionToDiffSigns(myDirection);
            x += diffSigns.x;
            y += diffSigns.y;
            const { baseLeft, baseTop } = getBasePosition(x, y, cellWidth, cellHeight);
            const width = (cellWidth - CELL_PADDING) * 0.5;
            const height = (cellHeight - CELL_PADDING) * 0.5;
            const markerLeft = baseLeft + ((cellWidth - width) / 2) - (CELL_PADDING / 2);
            const markerTop = baseTop + ((cellHeight - height) / 2) - (CELL_PADDING / 2);

            const v = width + CELL_PADDING;
            const altitude = v * 0.866;

            return (
                <div
                    key="player-next-marker"
                    className={`player-marker next direction-${myDirection}`}
                    style={{
                        left: markerLeft, top: markerTop, width: v, height: v, lineHeight: `${v}px`, color: getMarkerBorderColor(me.colorMode)
                    }}
                >
                    {playerPlayData?.nextMove?.cardNum}
                    <Stage className="shape-wrapper" width={v} height={v}>
                        <Layer>
                            <Shape
                                key={`player-next-marker-shape`}
                                x={0}
                                y={0}
                                fill={toRGBString(me.color)}
                                stroke={toRGBString(me.color)}
                                strokeWidth={1}
                                sceneFunc={(context, shape) => {
                                    context.beginPath();
                                    context.moveTo(v - altitude, 0);
                                    context.lineTo(v - altitude, v);
                                    context.lineTo(v, v / 2);
                                    context.lineTo(v - altitude, 0);
                                    context.closePath();
                                    context.fillStrokeShape(shape);
                                }}
                            />
                        </Layer>
                    </Stage>
                </div>
            );
        }
        return null;
    }, [players, playerPlayData, cellWidth, cellHeight]);

    return (
        <div className="player-markers">
            {playerMarkers}
            {moveDirectionIndicator}
        </div>
    );
}

function getBasePosition(x, y, cellWidth, cellHeight) {
    const baseLeft = (x * cellWidth) + (x * GUTTER_SIZE) + PADDING;
    const baseTop = (y * cellHeight) + (y * GUTTER_SIZE) + PADDING;
    return { baseLeft, baseTop };
}

function Cells({ cells, width, height, hCells, vCells, origin }) {
    const blendingRect = useMemo(() => ({
        narrowXOffset: (width - (BORDER_WIDTH * 2)) * (INNER_PADDING_RATIO),
        narrowYOffset: (height - (BORDER_WIDTH * 2)) * (INNER_PADDING_RATIO),
        wideXOffset: (width - (BORDER_WIDTH * 2)) * (BLENDING_RECT_THICKNESS + INNER_PADDING_RATIO),
        wideYOffset: (height - (BORDER_WIDTH * 2)) * (BLENDING_RECT_THICKNESS + INNER_PADDING_RATIO),
        shortWidth: (width - (BORDER_WIDTH * 2)) * BLENDING_RECT_THICKNESS,
        shortHeight: (height - (BORDER_WIDTH * 2)) * BLENDING_RECT_THICKNESS,
        longWidth: (width - (BORDER_WIDTH * 2)) * (1 - ((INNER_PADDING_RATIO + BLENDING_RECT_THICKNESS) * 2)),
        longHeight: (height - (BORDER_WIDTH * 2)) * (1 - ((INNER_PADDING_RATIO + BLENDING_RECT_THICKNESS) * 2))
    }), [width, height, INNER_PADDING_RATIO, BLENDING_RECT_THICKNESS]);

    const totalWidth = (width * hCells) + (GUTTER_SIZE * (hCells - 1));
    const totalHeight = (height * vCells) + (GUTTER_SIZE * (vCells - 1));

    const allCells = useMemo(() => {
        const rows = [];
        for (let j = 0; j < cells.length; j++) {
            const cols = [];
            for (let i = 0; i < cells[j].length; i++) {
                cols.push(<Cell key={`cell-${i}-${j}`} cell={cells[j][i]} width={width} height={height} blendingRect={blendingRect} />);
            }
            rows.push(
                <div key={`row-${j}`} className="cell-row">{cols}</div>
            );
        }
        return rows;
    }, [cells, width, height]);

    const xOffset = width * origin.x + GUTTER_SIZE * origin.x;
    let xOffsetString = `-${xOffset}px`;
    if (xOffset < 0) {
        xOffsetString = `${xOffset * -1}px`;
    }
    const yOffset = height * origin.y + GUTTER_SIZE * origin.y;
    let yOffsetString = `-${yOffset}px`;
    if (yOffset < 0) {
        yOffsetString = `${yOffset * -1}px`;
    }

    const styles = { width: `${totalWidth}px`, height: `${totalHeight}px`, transform: `translate(${xOffsetString}, ${yOffsetString})` };

    return (
        <div className="cells" style={styles}>
            {allCells}
        </div>
    );
}

function Cell({
    cell, width, height, blendingRect
}) {
    // Varying properties : Background color/image, Opacity, Border, Content(Flag, Shading)
    let backgroundColor = 'rgba(0, 0, 0, 0)';
    let opacity = 1;
    let borderColor = 'rgba(0, 0, 0, 0)';
    let content;

    if (isCellUncharted(cell)) {
        borderColor = '#5B5B5B';
        // TODO: add flag to content
    } else if (isCellUncolored(cell)) {
        opacity = OUT_OF_SIGHT_OPACITY;
        // TODO: add texture
    } else {
        backgroundColor = toRGBString(cell.color.color);
        if (!isCellVisible(cell)) {
            opacity = OUT_OF_SIGHT_OPACITY;
        } else {
            if (!isCellOpen(cell)) {
                opacity = CLOSED_OPACITY;
            }
            content = (
                <>
                    <div
                        className="blending-rect highlight"
                        style={{
                            left: `${blendingRect.wideXOffset}px`,
                            top: `${blendingRect.narrowYOffset}px`,
                            width: `${blendingRect.longWidth}px`,
                            height: `${blendingRect.shortHeight}px`
                        }}
                    />
                    <div
                        className="blending-rect highlight"
                        style={{
                            left: `${blendingRect.narrowXOffset}px`,
                            top: `${blendingRect.wideYOffset}px`,
                            width: `${blendingRect.shortWidth}px`,
                            height: `${blendingRect.longHeight}px`
                        }}
                    />
                    <div
                        className="blending-rect shadow"
                        style={{
                            left: `${blendingRect.wideXOffset + blendingRect.longWidth}px`,
                            top: `${blendingRect.wideYOffset}px`,
                            width: `${blendingRect.shortWidth}px`,
                            height: `${blendingRect.longHeight}px`
                        }}
                    />
                    <div
                        className="blending-rect shadow"
                        style={{
                            left: `${blendingRect.wideXOffset}px`,
                            top: `${blendingRect.wideYOffset + blendingRect.longHeight}px`,
                            width: `${blendingRect.longWidth}px`,
                            height: `${blendingRect.shortHeight}px`
                        }}
                    />
                </>
            );
        }
    }

    return (
        <div
            className="cell"
            style={{
                width: `${width}px`,
                height: `${height}px`,
                backgroundColor,
                borderColor,
                opacity
            }}
        >{content}
        </div>
    );
}

function isCellUncharted(cell) {
    if (cell.color?.depth === 0) {
        return true;
    }
    return false;
}

function isCellUncolored(cell) {
    if (!cell.color) {
        return true;
    }
    return false;
}

function isCellOpen(cell) {
    if (cell.closed === false) {
        return true;
    }
    return false;
}

function isCellVisible(cell) {
    if (cell.visible === true) {
        return true;
    }
    return false;
}

function pluckClosed(cells) {
    const rows = [];
    cells.forEach((cellRow) => {
        const row = [];
        cellRow.forEach((cell) => {
            row.push(cell.closed);
        });
        rows.push(row);
    });
    return rows;
}
