import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { WindowSizeGuard } from './hocs/WindowSizeGuard';
import { gameReducer } from './reducers/gameReducer';
import { Gameplay } from './pages/Gameplay';
import { Landing } from './pages/Landing';
import { Center } from './pages/Center';
import { HowToPage } from './pages/HowTo';
import { Profile } from './pages/Profile';
import { SignInPage } from './pages/SignIn';
import { RegisterPage } from './pages/Register';
import { ResetPasswordPage } from './pages/ResetPassword';
// import { Web3ConnectProvider } from './utils/web3';
// import { ControlCenter } from './pages/ControlCenter';
// import { Lobby } from './pages/Lobby';
// import { JoinLatest } from './pages/JoinLatest';
// import { GameResult } from './pages/GameResult';
// import { Collection } from './pages/Collection';
// import { MyTones } from './pages/MyTones';
// import { Account } from './pages/Account';
// import { Error } from './pages/Error';
// import { HallOfFame } from './pages/HallOfFame';
// import { ConnectWallet } from './pages/ConnectWallet';
// import { MintingCenter } from './pages/MintingCenter';
// import { CollectiveArtwork } from './pages/CollectiveArtwork';
// import { Admin } from './pages/Admin';
// import { UIDev } from './pages/UIDev';
// import { TutorialPlay } from './pages/TutorialPlay';
// import { TutorialTour } from './pages/TutorialTour';

import './App.scss';

const store = configureStore({ reducer: gameReducer });

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="game/:gameId" element={<WindowSizeGuard><ReduxProvider store={store}><Gameplay /></ReduxProvider></WindowSizeGuard>} />
                <Route path="game" element={<WindowSizeGuard><ReduxProvider store={store}><Gameplay /></ReduxProvider></WindowSizeGuard>} />
                <Route path="center" element={<WindowSizeGuard><Center /></WindowSizeGuard>} />
                <Route path="how-to" element={<WindowSizeGuard><HowToPage /></WindowSizeGuard>} />
                <Route path="profile" element={<WindowSizeGuard><Profile /></WindowSizeGuard>} />
                <Route path="sign-in" element={<WindowSizeGuard><SignInPage /></WindowSizeGuard>} />
                <Route path="register" element={<WindowSizeGuard><RegisterPage /></WindowSizeGuard>} />
                <Route path="reset-password" element={<WindowSizeGuard><ResetPasswordPage /></WindowSizeGuard>} />
                {/* <Route path="result/:gameId" element={<GameResult />} />
                <Route path="control" element={<ControlCenter />} />
                <Route path="lobby" element={<Lobby />} />
                <Route path="collection" element={<Collection />} />
                <Route path="tones" element={<MyTones />} />
                <Route path="account" element={<Account />} />
                <Route path="join" element={<JoinLatest />} />
                <Route path="ui-dev" element={<UIDev />} />
                <Route path="hof" element={<HallOfFame />} />
                <Route path="connect-wallet" element={<ConnectWallet />} />
                <Route path="mint" element={<Web3ConnectProvider><MintingCenter /></Web3ConnectProvider>} />
                <Route path="collective-artwork/:artworkTokenId" element={<Web3ConnectProvider><CollectiveArtwork /></Web3ConnectProvider>} />
                <Route path="error" element={<Error />} />
                <Route path="admin" element={<Admin />} />
                <Route path="poc-tutorial" element={<ReduxProvider store={store}><TutorialPlay /></ReduxProvider>} />
                <Route path="tutorial-tour" element={<TutorialTour />} /> */}
            </Routes>
        </Router>
    );
}

export default App;
