import { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';

import {
    GAME_ACTION_TYPES, GAME_EVENTS, GAME_STATES
} from '../const';

export const useGameSocket = (userToken, gameId) => {
    const [socket, setSocket] = useState();

    useEffect(() => {
        if (userToken && gameId) {
            const wsQuery = { userToken, toMatch: true };
            if (gameId !== 'not_assigned') {
                wsQuery.toMatch = false;
                wsQuery.gameId = gameId;
            }

            const newSocket = io(`${process.env.REACT_APP_GAME_WS_URI}/game`, {
                path: '/ws/',
                query: wsQuery,
                transports: ['websocket'],
                withCredentials: true
            });
            newSocket.on('connect_error', (err) => console.error(err));
            setSocket(newSocket);

            return () => newSocket.close();
        }
    }, [userToken, gameId]);

    return socket;
};

export const useGameEventDispatch = (socket, dispatch) => {
    useEffect(() => {
        if (socket && dispatch) {
            socket.on('players', (data) => {
                dispatch({
                    type: GAME_ACTION_TYPES.PLAYERS_UPDATED, players: data.players, waitingTime: data.max_waiting_time, requiredPlayers: data.required_players
                });
            });
            socket.on(GAME_EVENTS.TICK, (newTimeLeft) => {
                dispatch({ type: GAME_ACTION_TYPES.TICK, timeLeft: newTimeLeft });
            });
            socket.on(GAME_EVENTS.UPDATE, (payload) => {
                dispatch({ type: GAME_ACTION_TYPES.UPDATED, payload });
            });
            socket.on(GAME_EVENTS.RECONNECTED, (payload) => {
                if (payload.status === 'in_game') {
                    dispatch({ type: GAME_ACTION_TYPES.UPDATED, payload: payload.data });
                } else if (payload.status === 'waiting') {
                    dispatch({
                        type: GAME_ACTION_TYPES.PLAYERS_UPDATED, players: payload.data.players, waitingTime: payload.data.max_waiting_time, requiredPlayers: payload.data.required_players
                    });
                }
            });
            /*
            Pregame stage should gradually feed in the board, players and stats data
            */
            socket.on(GAME_EVENTS.PREGAME, (payload) => {
                const {
                    board, players, game, playerProfiles, deck
                } = payload;

                if (!board || !players) {
                    console.error('Malformed pregame data');
                    return;
                }
                const { missions } = game;

                dispatch({
                    type: GAME_ACTION_TYPES.PREGAME_DATA_RECEIVED,
                    data: {
                        board, playerProfiles, players, missions, deck
                    }
                });
            });
            socket.on(GAME_EVENTS.WAITING_TIMER, ({ gameWaitingTimer: newgameWaitingTimer }) => {
                dispatch({ type: GAME_ACTION_TYPES.GAME_WAITING_TIMER, gameWaitingTimer: newgameWaitingTimer });
            });
            return () => {
                socket.removeAllListeners();
                dispatch({ type: GAME_ACTION_TYPES.RESET });
            };
        }
    }, [socket, dispatch]);
};

// Since players array also includes myself,
// pick out myself first, then based on that,
// find other approaching players.
// Return an empty array if no one's found.
function findApproachingPlayerNames(players) {
    const me = players.find((player) => (player.isMe && player.isAlive));
    if (!me) {
        return [];
    }
    return players.filter((player) => (
        player.isAlive
            && !player.isMe
            && typeof player.x === 'number'
            && typeof player.y === 'number'
            && Math.abs(player.x - me.x) <= 1
            && Math.abs(player.y - me.y) <= 1))
        .map((player) => player.name);
}

// useGameUIStates listens to the socket and manage UI states based on the received events
export const useGameUIStates = (socket, userToken) => {
    // waiting -> pregame -> ingame -> postgame -> concluded
    const [gameState, setGameState] = useState(GAME_STATES.WAITING);
    const [result, setResult] = useState(null);
    const [connecting, setConnecting] = useState(true);
    const [playerStatePending, setPlayerStatePending] = useState(false);
    const [statusMessage, setStatusMessage] = useState('Loading');
    const [eventMessageQueue, setEventMessageQueue] = useState([]);

    const pushEventMessage = useCallback((newMessage) => {
        setEventMessageQueue((oldQueue) => ([...oldQueue, newMessage]));
    }, [setEventMessageQueue]);

    useEffect(() => {
        if (socket) {
            socket.on(GAME_EVENTS.CONNECTED, () => setConnecting(false));
            socket.on(GAME_EVENTS.RECONNECTED, (payload) => {
                setConnecting(false);
                if (payload.status === 'in_game') {
                    setGameState(GAME_STATES.INGAME);
                    setStatusMessage('You have been eliminated as disconnected from the game');
                } else if (payload.status === 'waiting') {
                    setGameState(GAME_STATES.WAITING);
                }
            });
            socket.on(GAME_EVENTS.PREGAME, () => {
                setGameState(GAME_STATES.PREGAME);
            });
            socket.on(GAME_EVENTS.STANDBY, ({ timeToStart }) => {
                if (timeToStart > 10) {
                    return;
                }
                if (timeToStart > 1) {
                    setStatusMessage(`Starting in ${timeToStart} seconds`);
                } else if (timeToStart === 1) {
                    setStatusMessage(`Starting in ${timeToStart} second`);
                } else {
                    setStatusMessage('Starting now');
                    pushEventMessage({
                        durationInSeconds: 1,
                        message: 'Starting now!',
                        popUp: true
                    });
                }
            });
            socket.on(GAME_EVENTS.STARTING, () => {
                setStatusMessage('Choose a card');
                setGameState(GAME_STATES.INGAME);
            });
            socket.on(GAME_EVENTS.CONCLUDED, () => {
                pushEventMessage({
                    durationInSeconds: 2,
                    message: 'We have the winner!',
                    popUp: true
                });
                setStatusMessage('Finalizing the result');
                setGameState(GAME_STATES.POSTGAME);
            });
            socket.on(GAME_EVENTS.COMPLETED, (r) => {
                setGameState(GAME_STATES.COMPLETED);
                setResult(r);
            });
            socket.on(GAME_EVENTS.PROMOTED, ({ promotion, eliminated }) => {
                if (promotion === 'knocked_out') {
                    pushEventMessage({
                        durationInSeconds: 2,
                        message: `Knocked out: ${eliminated.map((pl) => pl.playerTag).join(', ')}`
                    });
                }
            });
            socket.on(GAME_EVENTS.ELIMINATED, (payload) => {
                let eliminationMessage = 'You are eliminated :(';
                let eliminationMessageWithNums = 'You are eliminated :(';
                if (payload.eliminators && payload.eliminators.length > 0) {
                    eliminationMessage = `You are eliminated by ${payload.eliminators.map((eliminator) => eliminator.playerTag).join(',')}`;
                    eliminationMessageWithNums = `You are eliminated by ${payload.eliminators.map((eliminator) => (`${eliminator.playerTag}'s ${eliminator.drawnCardNum}`)).join(',')}`;
                }
                setStatusMessage(eliminationMessage);
                pushEventMessage({
                    type: 'alert',
                    durationInSeconds: 3,
                    message: eliminationMessageWithNums,
                    popUp: true
                });
            });
            socket.on(GAME_EVENTS.UPDATE, (payload) => {
                const { players, turn } = payload;
                if (!players || turn === 0) {
                    return;
                }
                // Do not send event messages for now.
                // sendEventMessagesOnUpdate(pushEventMessage, players);
            });
            socket.on(GAME_EVENTS.PLAYER_STATE_PENDING, () => {
                setPlayerStatePending(true);
            });
            socket.on(GAME_EVENTS.WAITING_TIMER, () => {
                setPlayerStatePending(false);
            });
            return () => socket.removeAllListeners();
        }
        setResult(null);
        setPlayerStatePending(false);

        return null;
    }, [socket, userToken]);

    return {
        gameState, result, connecting, playerStatePending, statusMessage, eventMessageQueue, setStatusMessage, pushEventMessage
    };
};

function sendEventMessagesOnUpdate(pushEventMessage, players) {
    const approachingPlayerNames = findApproachingPlayerNames(players);
    if (approachingPlayerNames.length > 0) {
        pushEventMessage({
            type: 'alert',
            durationInSeconds: 4, // A turn isn't usually short as 5~6 seconds currently. If this assumption ever changes, we'll have to revisit this.
            message: `${approachingPlayerNames.join(',')} ${approachingPlayerNames.length === 1 ? 'is' : 'are'} approaching`
        });
    }
}
