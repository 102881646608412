import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import { useD3Ref } from '../hooks/useD3Ref';

import './Chart.scss';

function BarChart({ data, neon, barWidth }) {

    const width = barWidth;
    const BAR_HEIGHT = 8;
    const BAR_BORDER_RADIUS = 4;
    const TRANSITION_DURATION = 800;

    const isMultipleBar = Array.isArray(data.value);
    const barYPos = !isMultipleBar ? 20 : 0;
    const barData = isMultipleBar ? data.value.map((val, i, arr) => (i > 0 ? val + arr[i - 1] : val)).reverse() : [data.value];
    const colors = [neon || isMultipleBar ? '#80FFDB' : 'url(\'#grad-teal\')', 'url(\'#grad-teal\')'];

    const svgRef = useD3Ref((chart) => {
        const xAxis = d3.scaleLinear()
            .range([0, width])
            .domain([0, data.total]);

        const mainChart = chart.select('.main-chart');

        mainChart.selectAll('.bar-fill')
            .data(barData)
            .attr('fill', (d, i) => colors[i])
            .transition()
            .duration(TRANSITION_DURATION)
            .attr('width', (d) => xAxis(d));
    }, [data, neon]);

    return (
        <svg className="chart-bar" ref={svgRef} width={width} height={barYPos + BAR_HEIGHT}>
            <defs>
                <linearGradient id="grad-teal">
                    <stop offset="50%" stopColor="#447292" />
                    <stop offset="100%" stopColor="#48B2BE" />
                </linearGradient>
            </defs>
            <g className="main-chart">
                <rect fill="#000" x={0} y={barYPos} width={width} rx={BAR_BORDER_RADIUS} ry={BAR_BORDER_RADIUS} height={BAR_HEIGHT} />
                {barData.map((d, index) => (
                    <rect className="bar-fill" key={`rect-${index}`} x={0} y={barYPos} rx={BAR_BORDER_RADIUS} ry={BAR_BORDER_RADIUS} height={BAR_HEIGHT} />
                ))}
                {!isMultipleBar && data.title && (
                    <g className="title-wrapper">
                        <text className="chart-title" fill="#fff">{data.title}</text>
                        <text className="chart-values" fill="#ffffffCC" x={width}>{data.value}/{data.total}</text>
                    </g>
                )}
            </g>
        </svg>
    );
}

function PieChart({ data }) {
    const SIZE = 230;
    const INNER_RADIUS = 103;
    const TRANSITION_DURATION = 800;

    const colors = ['url("#grad-pie")', '#000'];

    const svgRef = useD3Ref((chart) => {
        const pie = d3.pie()
            .value((d) => d)
            .sort(null); // disable auto sort

        const arc = d3.arc()
            .innerRadius(INNER_RADIUS)
            .outerRadius(SIZE / 2);

        const mainChart = chart.select('.main-chart');
        mainChart.select('.donut-bg')
            .data(pie([data.total]))
            .attr('d', arc)
            .attr('fill', '#000');

        mainChart.selectAll('.arc')
            .data(pie([data.value, data.total - data.value]))
            .attr('fill', (d, i) => colors[i])
            .attr('class', (d, i) => `arc-${i}`);

        // donut load animation
        mainChart.select('.arc-0')
            .transition()
            .duration(TRANSITION_DURATION)
            .attrTween('d', (d) => {
                const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
                return (t) => {
                    d.endAngle = i(t);
                    return arc(d);
                };
            });
    }, [data]);

    return (
        <svg className="chart-donut" ref={svgRef} width={SIZE} height={SIZE}>
            <defs>
                <linearGradient id="grad-pie" gradientTransform="rotate(90)">
                    <stop offset="20%" stopColor="#80FFDB" />
                    <stop offset="80%" stopColor="#48B2BE" />
                </linearGradient>
            </defs>
            <g className="main-chart" transform={`translate(${SIZE / 2}, ${SIZE / 2})`}>
                <path className="donut-bg" />
                <path className="arc" />
                <path className="arc" />
            </g>
            <g className="title-wrapper">
                <text className="pie-value" x={SIZE / 2} y={78} fill="url('#grad-pie')">{(data.value / data.total * 100).toFixed(1)}%</text>
                <text className="chart-values" x={SIZE / 2} y={120} fill="#fff">{data.value}/{data.total}</text>
                <text className="chart-title" x={SIZE / 2} y={150} fill="#fff">{data.title}</text>
            </g>
        </svg>
    );
}

export function Chart({
    type, data, width = 125, neon = false
}) {
    const wrapper = useRef(null)

    const chart = useMemo(() => {
        if (width < 1) return;
        switch (type) {
            case 'bar':
                return (
                    <BarChart data={data} neon={neon} barWidth={width} />
                );
            case 'donut':
                return (
                    <PieChart data={data} />
                );
            default:
                return (
                    <div>Invalid Chart Type</div>
                );
        }
    }, [data, type, neon, width]);
    return (
        <div className="chart-wrapper" ref={wrapper}>
            {chart}
        </div>
    );
}

Chart.propTypes = {
    type: PropTypes.oneOf(['bar', 'donut']),
    neon: PropTypes.optionalBoolean, // bar chart color
    data: PropTypes.exact({
        title: PropTypes.optionalString,
        total: PropTypes.number,
        value: PropTypes.oneOf([PropTypes.number, PropTypes.arrayOf(PropTypes.number)])
    }),
    width: PropTypes.number
};
