import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignIn } from '../components/SignIn';
import { BetaThanks } from '../components/BetaThanks';
import { LoadingGrid } from '../components/LoadingGrid';
import { DarkBackground } from '../components/DarkBackground';

import './Landing.scss';

export function Landing() {

    return (
        <div className="landing">
            <div className="center-content">
                <BetaThanks />
            </div>
            <DarkBackground gradation squares />
        </div>
    );
}
