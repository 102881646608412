import React, { useState, useCallback, useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const STEP_LENGTH = process.env.STEP_LENGTH || 4000;

export function JoyrideTour({ steps, shouldRun }) {
    const [runTour, setRunTour] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const handleJoyrideCallback = useCallback((data) => {
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status) || data.action === ACTIONS.CLOSE) {
            setRunTour(false);
        }
        if (data.type === 'tour:start' || data.action === 'next') {
            setTimeout(() => {
                setStepIndex(data.index + 1);
            }, STEP_LENGTH);
        }
    }, []);

    useEffect(() => {
        if (shouldRun) {
            setRunTour(true);
        }
    }, [shouldRun]);

    return (
        <Joyride
            hideCloseButton
            run={runTour}
            scrollToFirstStep
            showSkipButton
            disableCloseOnEsc
            disableOverlayClose
            hideBackButton
            steps={steps}
            stepIndex={stepIndex}
            callback={handleJoyrideCallback}
            styles={{
                options: {
                    zIndex: 10000
                }
            }}
        />
    );
}
