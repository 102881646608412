import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Lockable } from './Lockable';
import { Button } from './Button';

import './IconMenu.scss';

function IconMenuItem({
    iconClass, onClick, tooltip, isLocked, lockMessage
}) {
    return (
        <Lockable
            isLocked={isLocked}
            lockMessage={lockMessage}
            inline
        >
            <Button contentType="iconOnly" icon={iconClass.replace('bi-', '')} onClick={onClick} data-tip={tooltip} />
        </Lockable>
    );
}

export function IconMenu({ menuItems }) {
    // const navigate = useNavigate();
    // const toHof = useCallback(() => navigate('/hof'), []);
    // const userToken = useContext(UserTokenContext);
    // const [userStatus, setUserStatus] = useState();

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_GAME_REST_URI}/users/status`, {
    //         headers: {
    //             Authorization: `Bearer ${userToken}`
    //         }
    //     }).then((response) => setUserStatus(response.data));
    // }, [userToken]);

    const buttons = menuItems.map((menuItem) => (
        <IconMenuItem key={`menu-item-${menuItem.iconClass.replace(' ', '-')}`} {...menuItem} />
    ));

    return (
        <div className="icon-menu">
            {buttons}
            <ReactTooltip />
        </div>
    );
}

IconMenu.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.object)
};
