import React, {
    useEffect, useState, useMemo, useContext
} from 'react';

import { toRGBString, toneCodeToRGBColor } from '../utils/color';
import { UserDataContext } from '../contexts/userDataContext';
import { TONE_IMPORT_ADDING_INTERVAL } from '../const';

import './ToneStack.scss';

export function ToneStackInner({ stack }) {
    const blocks = useMemo(() => stack.map((toneCode, idx) => ((
        <div key={`tone-block-wrapper-${idx}`} className="tone-stack-block-wrapper">
            <div key={`tone-block-${idx}`} className="tone-stack-block" style={{ backgroundColor: toRGBString(toneCodeToRGBColor(toneCode)) }} />
        </div>
    ))), [stack]);

    return (
        <div id="tone-stack">
            {blocks}
        </div>
    );
}

export function ToneStack({ added, onItemAppeared }) {
    const { toneStack } = useContext(UserDataContext);
    const [appearedAddedTone, setAppearedAddedTone] = useState([]);

    // Reset appeared added tones when tonestack is updated
    useEffect(() => {
        if (toneStack.length > 0) {
            setAppearedAddedTone([]);
        }
    }, [toneStack]);

    useEffect(() => {
        const appearInterval = setInterval(() => {
            if (!added || appearedAddedTone.length === added.length) {
                clearInterval(appearInterval);
                return;
            }
            const notAppeared = added.find((newAdded, idx) => {
                if (idx >= appearedAddedTone.length) {
                    return true;
                }
                return false;
            });
            if (onItemAppeared) {
                onItemAppeared(notAppeared);
            }
            setAppearedAddedTone([...appearedAddedTone, notAppeared]);
        }, TONE_IMPORT_ADDING_INTERVAL);
        return () => {
            clearInterval(appearInterval);
        };
    }, [toneStack, added, appearedAddedTone]);

    const allTones = useMemo(() => {
        if (!appearedAddedTone || appearedAddedTone.length === 0) {
            return toneStack;
        }
        return [...toneStack, ...appearedAddedTone];
    }, [toneStack, appearedAddedTone]);

    return (
        <ToneStackInner stack={allTones} />
    );
}
