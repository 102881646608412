import React from 'react';

import { Tile } from './Tile';
import { NavButton } from './NavButton';

import './BetaWelcome.scss';

const NOTICES = [
    'First, for now, our game only supports larger screens (laptops/monitors). We apologize for any inconvenience.. :(',
    "Current version is barebones! Our hope is for you to test out the basic mechanics and the gameplay through this program.",
    "You will be assigned to one of four teams and each team will be competing againt each other. A prize will be given to the winning team!",
    "Please join our Discord server and share your findings. We appreciate and NEED ANY feedback, literally, anything (ear emoticon) (link)",
    "Thank you VERY much! You are helping us make this game into a reality :)"
];

export function BetaWelcome() {
    return (
        <Tile>
            <div className="beta-welcome">
                <div className="main-welcome-message">Thanks for signing up for Blend Private Beta!</div>
                <div>Here are few things to remember:</div>
                <div className="notices">
                    {NOTICES.map((notice, idx) => (
                        <div key={`notice-${idx}`} className="notice"><span className="bullet">*</span>{notice}</div>
                    ))}
                </div>
                <NavButton label="Let's do it." to="/" />
            </div>
        </Tile>
    );
}
