import React, { useMemo, useRef, useEffect } from 'react';
import Mustache from 'mustache';

import { StatDisplays } from '../utils/stats';
import { getAccomplishStatus } from '../utils/mission';

import './GameMissions.scss';

function getMissionBodyText(accomplishStatus, mission, displayConfig) {
    const formattedThreshold = displayConfig.formatter(mission.direction === 'lower' ? mission.stat.p20 : mission.stat.p80);

    let bodyText;

    if (displayConfig.missionBodyTextTemplate) {
        try {
            const template = displayConfig.missionBodyTextTemplate[mission.direction][accomplishStatus.isAccomplished ? 'accomplished' : 'notAccomplished'];
            bodyText = Mustache.render(template, {
                threshold: formattedThreshold
            });
        } catch (ex) {
            console.warn('Could not render stat text. Fallback to generic pattern.');
        }
    }

    if (!bodyText) {
        if (mission.direction === 'lower') {
            if (accomplishStatus.isAccomplished) {
                if (accomplishStatus.isFinal) {
                    bodyText = `You did it! You have made ${displayConfig.displayName} below ${formattedThreshold}!`;
                } else {
                    bodyText = `Keep ${displayConfig.displayName} below ${formattedThreshold}!`;
                }
            } else {
                if (accomplishStatus.isFinal) {
                    bodyText = `Failed to lower ${displayConfig.displayName} below ${formattedThreshold}.`;
                } else {
                    bodyText = `Lower ${displayConfig.displayName} below ${formattedThreshold}!`;
                }
            }
        } else {
            if (accomplishStatus.isAccomplished) {
                if (accomplishStatus.isFinal) {
                    bodyText = `You did it! You have made ${displayConfig.displayName} above ${formattedThreshold}!`;
                } else {
                    bodyText = `Keep ${displayConfig.displayName} above ${formattedThreshold}!`;
                }
            } else {
                if (accomplishStatus.isFinal) {
                    bodyText = `Failed to increase ${displayConfig.displayName} above ${formattedThreshold}.`;
                } else {
                    bodyText = `Increase ${displayConfig.displayName} above ${formattedThreshold}!`;
                }
            }
        }
    }
    return bodyText;
}

function getTrendClassName(oldValue, newValue) {
    if (newValue > oldValue) {
        return 'increase';
    }
    if (newValue < oldValue) {
        return 'decrease';
    }
    return 'steady';
}

export function GameMission({ missionName, mission }) {
    const lastValue = useRef();
    const currentValue = useRef();
    useEffect(() => {
        lastValue.current = currentValue.current;
        currentValue.current = mission.stat.value;
    }, [mission.stat.value]);
    
    const accomplishStatus = getAccomplishStatus(mission);
    let missionClasses = ['game-mission'];
    if (accomplishStatus.isAccomplished) {
        missionClasses.push('accomplished');
        if (accomplishStatus.isFinal) {
            missionClasses.push('final');
        } else {
            missionClasses.push('interim');
        }
    } else {
        missionClasses.push('not-accomplished');
        if (accomplishStatus.isFinal) {
            missionClasses.push('final');
        } else {
            missionClasses.push('interim');
        }
    }
    
    const bodyText = getMissionBodyText(accomplishStatus, mission, StatDisplays[missionName]);
    const formattedCurrentValue = !!mission.stat.value ? StatDisplays[missionName].formatter(mission.stat.value) : 'TBD';
    return (
        <div className={missionClasses.join(' ')}>
            <div className="mission-body">{bodyText}</div>
            <div className="mission-stat">
                <span className="stat">{formattedCurrentValue}</span>
                {
                    (!!lastValue.current && !!currentValue.current) &&
                    <i className={`bi bi-arrow-right-short ${getTrendClassName(lastValue.current, currentValue.current)}`} />
                }
            </div>
        </div>
    );
}

export function GameMissions({
    missions,
    width
}) {
    const containerStyle = useMemo(() => {
        const styles = {};
        if (width) {
            styles['width'] = `${width}px`;
        }
        return styles;
    }, [width]);
    return (
        <div className="game-missions" style={containerStyle}>
            <div className="title">Missions</div>
            <div className="missions">
                {Object.keys(missions).filter((missionName) => missions[missionName].mono).map((missionName, index) => (
                    <GameMission missionName={missionName} mission={missions[missionName]} />
                ))}
            </div>
        </div>
    );
}
