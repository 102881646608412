import React, {
    useMemo, useState, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import {
    Stage, Layer, Rect, Line
} from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';

import { toRGBString } from '../utils/color';
import { calcPos, traceBorder } from '../utils/gameBoard';
import StripesImage from '../assets/stripes_darker.svg';

import './BoardWithStatus.scss';

const DEFAULT_GUTTER_SIZE = 5;
const DEFAULT_BORDER_WIDTH = 8;
const ANIMATION_DURATION_MILLISECOND = 1000;
const OUT_OF_SIGHT_OPACITY = 0.3;
const BORDER_COLOR = '#797F94';
const BORDER_RADIUS_RATIO = 0.15;
const INNER_PADDING_RATIO = 0.03;
const BLENDING_RECT_THICKNESS = 0.09;

export function CanvasBoard({
    boardDOMRef,
    boardPositionService,
    boardWidth,
    boardHeight,
    boardWidthPx,
    boardHeightPx,
    colors,
    visibility,
    revealed,
    closed,
    touched,
    gutterSize = DEFAULT_GUTTER_SIZE,
    borderWidth = DEFAULT_BORDER_WIDTH
}) {
    const [spots, setSpots] = useState();
    const [oldSpots, setOldSpots] = useState();
    const [blendingRects, setBlendingRects] = useState([]);
    const [stripePattern] = useImage(StripesImage);
    const spotsRef = useRef();
    const oldSpotsRef = useRef();

    const spotsCrossFade = new Konva.Animation((frame) => {
        if (frame.time >= ANIMATION_DURATION_MILLISECOND) {
            if (oldSpotsRef.current) {
                oldSpotsRef.current.opacity(0);
            }
            if (spotsRef.current) {
                spotsRef.current.opacity(1);
            }
        } else {
            if (oldSpotsRef.current) {
                oldSpotsRef.current.opacity(1 - (frame.time * 1 / ANIMATION_DURATION_MILLISECOND));
            }
            if (spotsRef.current) {
                spotsRef.current.opacity(frame.time * 1 / ANIMATION_DURATION_MILLISECOND);
            }
        }
    });

    const positionService = useMemo(() => {
        const rectWidth = (boardWidthPx - (borderWidth * 2) - ((boardWidth + 1) * gutterSize)) / boardWidth;
        const rectHeight = (boardHeightPx - (borderWidth * 2) - ((boardHeight + 1) * gutterSize)) / boardHeight;
        return (x, y) => ({
            left: calcPos(x, rectWidth, gutterSize, borderWidth),
            top: calcPos(y, rectHeight, gutterSize, borderWidth),
            width: rectWidth,
            height: rectHeight
        });
    }, [boardWidth, boardHeight, boardWidthPx, boardHeightPx, borderWidth, gutterSize]);
    if (boardPositionService) {
        boardPositionService.current = positionService;
    }

    useEffect(() => {
        const rectWidth = (boardWidthPx - (borderWidth * 2) - ((boardWidth + 1) * gutterSize)) / boardWidth;
        const rectHeight = (boardHeightPx - (borderWidth * 2) - ((boardHeight + 1) * gutterSize)) / boardHeight;
        const cornerRadius = rectWidth * BORDER_RADIUS_RATIO;

        const blendingRectNarrowXOffset = rectWidth * (INNER_PADDING_RATIO);
        const blendingRectNarrowYOffset = rectHeight * (INNER_PADDING_RATIO);
        const blendingRectWideXOffset = rectWidth * (BLENDING_RECT_THICKNESS + INNER_PADDING_RATIO);
        const blendingRectWideYOffset = rectHeight * (BLENDING_RECT_THICKNESS + INNER_PADDING_RATIO);
        const blendingRectShortWidth = rectWidth * BLENDING_RECT_THICKNESS;
        const blendingRectShortHeight = rectHeight * BLENDING_RECT_THICKNESS;
        const blendingRectLongWidth = rectWidth * (1 - ((INNER_PADDING_RATIO + BLENDING_RECT_THICKNESS) * 2));
        const blendingRectLongHeight = rectHeight * (1 - ((INNER_PADDING_RATIO + BLENDING_RECT_THICKNESS) * 2));

        const ss = [];
        const newBlendingRects = [];
        for (let j = 0; j < boardHeight; j++) {
            for (let i = 0; i < boardWidth; i++) {
                const rectX = calcPos(i, rectWidth, gutterSize, borderWidth);
                const rectY = calcPos(j, rectHeight, gutterSize, borderWidth);

                const fillProps = {};
                if (colors?.[j]?.[i]?.depth === 0) {
                    fillProps.fill = 'rgba(255,255,255,0)';
                    fillProps.stroke = '#5B5B5B';
                    fillProps.strokeWidth = 1;
                } else if (colors?.[j]?.[i]) {
                    fillProps.fill = toRGBString(colors[j][i].color);
                } else {
                    fillProps.fill = 'rgba(255,255,255,0)';
                }
                ss.push(
                    <Rect
                        key={`spot-${i}-${j}`}
                        x={rectX}
                        y={rectY}
                        width={rectWidth}
                        height={rectHeight}
                        opacity={visibility?.[j]?.[i] ? 1 : OUT_OF_SIGHT_OPACITY}
                        cornerRadius={cornerRadius}
                        {...fillProps}
                    />
                );
                if ((revealed || visibility?.[j]?.[i] === true) && colors[j][i].depth > 0) {
                    newBlendingRects.push(
                        <Rect
                            key={`blending-rect-up-${i}-${j}`}
                            x={rectX + blendingRectWideXOffset}
                            y={rectY + blendingRectNarrowYOffset}
                            width={blendingRectLongWidth}
                            height={blendingRectShortHeight}
                            opacity={visibility?.[j]?.[i] ? 1 : OUT_OF_SIGHT_OPACITY}
                            cornerRadius={1}
                            fill="rgba(255,255,255,0.3)"
                            globalCompositeOperation="overlay"
                        />
                    );
                    newBlendingRects.push(
                        <Rect
                            key={`blending-rect-left-${i}-${j}`}
                            x={rectX + blendingRectNarrowXOffset}
                            y={rectY + blendingRectWideYOffset}
                            width={blendingRectShortWidth}
                            height={blendingRectLongHeight}
                            opacity={visibility?.[j]?.[i] ? 1 : OUT_OF_SIGHT_OPACITY}
                            cornerRadius={1}
                            fill="rgba(255,255,255,0.3)"
                            globalCompositeOperation="overlay"
                        />
                    );
                    newBlendingRects.push(
                        <Rect
                            key={`blending-rect-right-${i}-${j}`}
                            x={rectX + blendingRectWideXOffset + blendingRectLongWidth}
                            y={rectY + blendingRectWideYOffset}
                            width={blendingRectShortWidth}
                            height={blendingRectLongHeight}
                            opacity={visibility?.[j]?.[i] ? 1 : OUT_OF_SIGHT_OPACITY}
                            cornerRadius={1}
                            fill="rgba(0,0,0,0.3)"
                            globalCompositeOperation="overlay"
                        />
                    );
                    newBlendingRects.push(
                        <Rect
                            key={`blending-rect-down-${i}-${j}`}
                            x={rectX + blendingRectWideXOffset}
                            y={rectY + blendingRectWideYOffset + blendingRectLongHeight}
                            width={blendingRectLongWidth}
                            height={blendingRectShortHeight}
                            opacity={visibility?.[j]?.[i] ? 1 : OUT_OF_SIGHT_OPACITY}
                            cornerRadius={1}
                            fill="rgba(0,0,0,0.3)"
                            globalCompositeOperation="overlay"
                        />
                    );
                }
            }
        }
        if (spots) {
            setOldSpots(spots);
        }
        setSpots(ss);
        setBlendingRects(newBlendingRects);
        spotsCrossFade.start();
    }, [boardWidth, boardHeight, boardWidthPx, boardHeightPx, colors, stripePattern, visibility, setSpots, setOldSpots, closed, borderWidth, gutterSize, revealed, touched]);

    return (
        <div className="board" ref={boardDOMRef}>
            <Stage width={boardWidthPx} height={boardHeightPx}>
                <Layer ref={spotsRef}>
                    {spots}
                </Layer>
                <Layer ref={oldSpotsRef}>
                    {oldSpots}
                </Layer>
                <Layer>
                    {blendingRects}
                </Layer>
                <Layer>
                    <Border
                        closed={closed}
                        borderWidth={borderWidth}
                        boardWidth={boardWidth}
                        boardWidthPx={boardWidthPx}
                        gutterSize={gutterSize}
                    />
                </Layer>
            </Stage>
        </div>
    );
}

CanvasBoard.propTypes = {
    boardDOMRef: PropTypes.any,
    boardPositionService: PropTypes.any,
    boardWidth: PropTypes.number,
    boardHeight: PropTypes.number,
    boardWidthPx: PropTypes.number,
    boardHeightPx: PropTypes.number,
    gutterSize: PropTypes.number,
    borderWidth: PropTypes.number,
    colors: PropTypes.array,
    visibility: PropTypes.array,
    revealed: PropTypes.bool,
    closed: PropTypes.array,
    touched: PropTypes.array
};

function Border({
    closed, borderWidth, boardWidth, boardWidthPx, gutterSize
}) {
    if (!closed) {
        return null;
    }
    const rectSize = (boardWidthPx - (borderWidth * 2) - ((boardWidth + 1) * gutterSize)) / boardWidth;
    const borderPoints = traceBorder(closed, rectSize, gutterSize, borderWidth);
    if (borderPoints.length === 0) {
        return null;
    }
    return (
        <Line
            points={borderPoints.map(({ x, y }) => [x, y]).flat()}
            stroke={BORDER_COLOR}
            strokeWidth={borderWidth}
            closed
        />
    );
}
