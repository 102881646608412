import React, { useMemo, useLayoutEffect, useState } from 'react';
import { throttle } from 'lodash';

export const VIEW_MODE = {
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait'
};


export const GAME_TOP_HEIGHT = 20;
export const GAME_BOTTOM_HEIGHT = 40;

function getViewMode(width, height) {
    if (width >= height + GAME_TOP_HEIGHT + GAME_BOTTOM_HEIGHT) {
        return VIEW_MODE.LANDSCAPE;
    }
    return VIEW_MODE.PORTRAIT;
}

export const useGameSize = () => {
    const [viewMode, setViewMode] = useState();
    const [gameWidthPx, setGameWidthPx] = useState();
    const [gameHeightPx, setGameHeightPx] = useState();
    const [sideAvailableWidth, setSideAvailableWidth] = useState(0);

    const getGameSize = useMemo(() => () => {
        const { innerWidth, innerHeight } = window;
        const offsetWidth = innerWidth;
        const offsetHeight = innerHeight - 134;
        const mode = getViewMode(offsetWidth, offsetHeight);
        setViewMode(mode);
        if (mode === VIEW_MODE.LANDSCAPE) {
            const size = offsetHeight - GAME_TOP_HEIGHT - GAME_BOTTOM_HEIGHT;
            setGameWidthPx(size);
            setGameHeightPx(size);
            setSideAvailableWidth((offsetWidth - size) / 2);
        } else {
            const size = Math.min(offsetHeight - GAME_TOP_HEIGHT - GAME_BOTTOM_HEIGHT, offsetWidth);
            setGameWidthPx(size);
            setGameHeightPx(size);
        }
    }, []);

    useLayoutEffect(() => {
        if (getGameSize) {
            getGameSize();
            window.addEventListener('resize', throttle(getGameSize, 50));
            return () => {
                window.removeEventListener('resize', throttle(getGameSize, 50));
            };
        }
        return null;
    }, [getGameSize]);

    return {
        viewMode, gameWidthPx, gameHeightPx, sideAvailableWidth
    };
};
