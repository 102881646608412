export const GAME_ACTION_TYPES = {
    PREGAME_DATA_RECEIVED: 'pregame_data_received',
    UPDATED: 'updated',
    TICK: 'tick',
    PLAYERS_UPDATED: 'players_updated',
    RESET: 'reset',
    LOG_MESSAGE_RECEIVED: 'log_message_received',
    GAME_START_STANDBY: 'game_start_standby',
    GAME_WAITING_TIMER: 'game_wating_timer'
};

export const GAME_EVENTS = {
    AWAIT_RECONNECT: 'await_reconnect',
    CONNECTED: 'connected',
    RECONNECTED: 'reconnected',
    STANDBY: 'game_start_standby',
    STARTING: 'game_starting',
    CONCLUDED: 'game_concluded',
    COMPLETED: 'game_completed',
    PROMOTED: 'promoted',
    ELIMINATED: 'eliminated',
    PLAYER_STATE_PENDING: 'player_state_pending',
    WAITING_TIMER: 'game_waiting_timer',
    TICK: 'tick',
    UPDATE: 'update',
    PREGAME: 'pregame'
};

export const GAME_STATES = {
    WAITING: 'waiting',
    PREGAME: 'pregame',
    INGAME: 'ingame',
    POSTGAME: 'postgame',
    COMPLETED: 'completed'
};

export const POPUP_MESSAGE_TYPES = {
    STANDBY: 'standby',
    ALERT: 'alert',
    EVENT: 'event'
};

export const TONE_IMPORT_ADDING_INTERVAL = 200;
export const TONE_IMPORT_ADDING_POST_DELAY = 1500;

export const DIRECTION_COLOR_MAP = {
    'l': '#4e4e7c',
    'u': '#8e3a96',
    'd': '#447292',
    'r': '#48b2be'
};
