import React, {
    createContext, useEffect, useState, useCallback, useContext
} from 'react';
import axios from 'axios';

import { UserTokenContext } from './userTokenContext';

export const UserDataContext = createContext();

export function UserDataProvider({ children }) {
    const { userToken } = useContext(UserTokenContext);
    const [toneStack, setToneStack] = useState([]);

    const getUserToneStack = useCallback(() => {
        axios.get(`${process.env.REACT_APP_GAME_REST_URI}/tone-stack`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        }).then((response) => setToneStack(response.data.stack));
    }, [userToken]);

    useEffect(() => {
        if (userToken) {
            getUserToneStack();
        }
    }, [userToken, getUserToneStack]);

    const requestUserToneReward = useCallback((gameId, stack) => {
        if (userToken) {
            return axios.post(`${process.env.REACT_APP_GAME_REST_URI}/tone-stack/game/${gameId}/user`, {
                stack
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.status === 202) {
                    getUserToneStack();
                }
            });
        }
        return Promise.resolve();
    }, [userToken, getUserToneStack]);

    return (
        <UserDataContext.Provider value={{ toneStack, requestUserToneReward }}>
            {children}
        </UserDataContext.Provider>
    );
}
