import React, {
    useContext, useEffect, useState, useMemo
} from 'react';
import axios from 'axios';

import { RequireAuthenticated, UserTokenContext } from '../contexts/userTokenContext';
import { TopNav } from '../components/TopNav';
import { LevelTag } from '../components/LevelTag';
import { Chart } from '../components/Chart';
import { TonePalette } from '../components/TonePalette';
import { DarkBackground } from '../components/DarkBackground';
import { getUniqueToneCount, getTotalToneCount } from '../utils/tones.js';
import logo from '../assets/logo.svg';

import './Profile.scss';

export function ProfileView({ user, tones }) {
    const statsSection = useMemo(() => {
        if (!user) {
            return <div>Loading...</div>;
        }
        return (
            <div className="player-profile-stats">
                <div className="spacing" />
                <div className="profile-row">
                    <span className="player-tag">{user.player_tag}</span>
                    <LevelTag level={user.level} />
                </div>
                <div className="profile-row overall">
                    <div className="level-tones">
                        <Chart
                            type="bar"
                            width={254}
                            data={{
                                total: user.total_tones + user.tones_to_next_level + user.games_won + user.games_won_to_next_level,
                                value: [user.games_won, user.total_tones]
                            }}
                        />
                    </div>
                    <div className="to-next">
                        <span className="next">NEXT</span>
                        <span>{user.tones_to_next_level} Tones + {user.games_won_to_next_level} Wins</span>
                    </div>
                </div>
                <div className="profile-row wins">
                    <img src={logo} id="logo" alt="logo" />
                    <Chart
                        type="bar"
                        width={214}
                        data={{
                            title: 'Wins This Level',
                            total: user.games_won + user.games_won_to_next_level,
                            value: user.games_won
                        }}
                    />
                </div>
                <div className="profile-row tones">
                    <img src={logo} id="logo" alt="logo" />
                    <Chart
                        type="bar"
                        width={214}
                        neon
                        data={{
                            title: 'Tones This Level',
                            total: user.total_tones + user.tones_to_next_level,
                            value: user.total_tones
                        }}
                    />
                </div>
                {user.level % 10 === 4 && (<div className="profile-row top-class-played">
                    <img src={logo} id="logo" alt="logo" />
                    <Chart
                        type="bar"
                        width={214}
                        neon
                        data={{
                            title: 'Top Class to Play',
                            total: Math.min(user.top_class_played_at_current_level + user.top_class_played_to_next_level, 1),
                            value: Math.min(user.top_class_played_at_current_level, 1)
                        }}
                    />
                </div>)}
                {user.level % 10 === 9 && (<div className="profile-row top-class-won">
                    <img src={logo} id="logo" alt="logo" />
                    <Chart
                        type="bar"
                        width={214}
                        neon
                        data={{
                            title: 'Top Class to Win',
                            total: Math.min(user.top_class_won_at_current_level + user.top_class_won_to_next_level, 1),
                            value: Math.min(user.top_class_won_at_current_level, 1)
                        }}
                    />
                </div>)}
                <div className="profile-row rate">
                    {user.games_played !== 0 && (
                        <Chart
                            type="donut"
                            data={{
                                title: 'Winning Rate',
                                total: user.games_played,
                                value: user.games_won
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }, [user]);

    const tonesSection = useMemo(() => {
        const totalTones = getTotalToneCount(tones);
        return (
            <div className="player-tones">
                <div className="tone-count"><span className="count">{totalTones}</span><span className="text">Tones</span></div>
                <div className="tone-count-unique"><span className="count">{getUniqueToneCount(tones)}</span><span className="text">Unique Colors</span></div>
                <div>
                    {totalTones > 0 ?
                        <TonePalette tones={tones} bucketized /> :
                        <div className="no-tone-message">
                            <p>You have not earned any tones just yet.</p>
                        </div>
                    }
                </div>
            </div>
        );
    }, [tones]);

    return (
        <div className="profile">
            <div className="profile-inner grid">
                {statsSection}
                {tonesSection}
            </div>
            <DarkBackground gradation squares />
        </div>
    );
}

function ProfileInner() {
    const { userToken } = useContext(UserTokenContext);
    const [user, setUser] = useState();
    const [tones, setTones] = useState({});

    useEffect(() => {
        if (userToken) {
            const dataPromises = [
                axios.get(`${process.env.REACT_APP_GAME_REST_URI}/users/player-profile`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }),
                axios.get(`${process.env.REACT_APP_GAME_REST_URI}/tones`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                })
            ];
            Promise.all(dataPromises).then(([responseUser, responseTones]) => {
                if (responseUser.data) {
                    setUser(responseUser.data);
                }
                if (responseTones?.data?.unminted) {
                    setTones(responseTones?.data?.unminted);
                }
            });
        }
    }, [userToken]);

    return <ProfileView user={user} tones={tones} />;
}

export function Profile(props) {
    return (
        <RequireAuthenticated to="profile">
            <div>
                <TopNav />
                <ProfileInner {...props} />
            </div>
        </RequireAuthenticated>
    );
}
