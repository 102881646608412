import React, { useState, useEffect } from 'react';
import './RotatingSections.scss';

const ROTATE_INTERVAL = 4000;
const FADE_OUT_DURATION = 500;

export function RotatingSections({ interval, children }) {
    const [currentSectionIdx, setCurrentSectionIdx] = useState(0);
    const [fadingOut, setFadingOut] = useState(false);

    useEffect(() => {
        const rotateInterval = setInterval(() => {
            setFadingOut(true);
            setTimeout(() => {
                setFadingOut(false);
                setCurrentSectionIdx((oldValue) => {
                    if (oldValue + 1 === children.length) {
                        return 0;
                    }
                    return oldValue + 1;
                });
            }, FADE_OUT_DURATION);            
        }, (interval || ROTATE_INTERVAL));

        return () => clearInterval(rotateInterval);
    }, [interval, children, setFadingOut, setCurrentSectionIdx]);

    if (!children.length) {
        return children;
    }

    return (
        <div className="rotating-sections">
            <div className={`current-section${fadingOut ? ' fading-out' : ''}`}>{children[currentSectionIdx]}</div>
        </div>
    );
}