import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './TextField.scss';

export function TextField({
    label, hint, id, className, type, maxLength, onChange, defaultValue, disabled, onKeyDown, autoFocus
}) {
    const [textfieldStatus, setTextfieldStatus] = useState(!!defaultValue); // 0: default, 1: focus
    return (
        <div className={`textfield ${textfieldStatus ? 'focused' : ''}`}>
            <input
                id={id || 'textfield-input'}
                className={className}
                type={type}
                maxLength={maxLength}
                onFocus={() => setTextfieldStatus(true)}
                autoFocus={autoFocus}
                onBlur={(e) => { if (!e.target.value) setTextfieldStatus(false); }}
                defaultValue={defaultValue}
                onChange={onChange}
                onKeyDown={onKeyDown}
                disabled={disabled}
            />
            <label htmlFor={id || 'textfield-input'}>{label}</label>
            {hint && <div className="textfield-hint">{hint}</div>}
        </div>
    );
}

TextField.propTypes = {
    label: PropTypes.string.isRequired,
    hint: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.func
};
