import React, {
    useState, useMemo, useEffect, useContext, useCallback
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { GAME_STATES } from '../const';
import { UserTokenContext, UserTokenProvider } from '../contexts/userTokenContext';
import { UserDataProvider } from '../contexts/userDataContext';
import { GameDataProvider } from '../contexts/GameDataContext';
import { setLocalConfig } from '../utils/user';
import { useGameSocket, useGameEventDispatch, useGameUIStates } from '../hooks/gameplay';
import { GameplayView } from '../components/GameplayView';
import { GameResult } from '../components/GameResult';
import { GameWaiting } from '../components/GameWaiting';

function GameplayIO({ reset, gameId, children }) {
    const { userToken } = useContext(UserTokenContext);
    const socket = useGameSocket(userToken, gameId || 'not_assigned');
    const dispatch = useDispatch();
    useGameEventDispatch(socket, dispatch);

    const joinNewGame = useCallback((isEnded) => {
        let escapePromise;
        if (socket && !isEnded) {
            escapePromise = new Promise((resolve) => {
                socket.on('escape_received', resolve);
            });
            socket.emit('escape_request');
        } else {
            escapePromise = Promise.resolve();
        }
        escapePromise.then(reset);
    }, [socket, reset]);

    // Present an empty div while establishing ws connection
    if (!socket) {
        return <div />;
    }

    const childrenWithSocket = React.Children.map(children, (child) => (
        React.cloneElement(child, {
            socket,
            joinNewGame,
            isEphemeral: !userToken
        })
    ));

    return (
        <>
            {childrenWithSocket}
        </>
    );
}

function mergeArrays({
    colors, closed, touched, visibility
}) {
    const merged = [];
    if (colors) {
        for (let j = 0; j < colors.length; j++) {
            merged.push([]);
            for (let i = 0; i < colors[j].length; i++) {
                merged[j].push({
                    color: colors[j][i],
                    closed: closed[j][i],
                    touched: touched[j][i],
                    visible: visibility[j][i]
                });
            }
        }
    }
    return merged;
}

export function GameplayInner({ socket, joinNewGame, isEphemeral }) {
    const navigate = useNavigate();

    const board = useSelector((state) => state.board);
    const timeLeft = useSelector((state) => state.timeLeft);
    const waitingPlayers = useSelector((state) => state.waitingPlayers);
    const players = useSelector((state) => state.players);
    const playerProfiles = useSelector((state) => state.playerProfiles);
    const deck = useSelector((state) => state.deck);
    const logMessages = useSelector((state) => state.logMessages);
    const missions = useSelector((state) => state.missions);
    const turnLength = useSelector((state) => state.turnLength);
    const gameWaitingTimer = useSelector((state) => state.gameWaitingTimer);
    const minWaitingTime = useSelector((state) => state.minWaitingTime);
    const requiredPlayers = useSelector((state) => state.requiredPlayers);
    const turn = useSelector((state) => state.turn);
    const viewport = useSelector((state) => state.viewport);

    const [playerPlayData, setPlayerPlayData] = useState();
    const { userToken } = useContext(UserTokenContext);
    const {
        gameState, result, playerStatePending, statusMessage, eventMessageQueue
    } = useGameUIStates(socket, userToken);

    const me = useMemo(() => players.find((player) => player.isMe), [players]);

    const onCardSelect = useCallback((cardIdx) => {
        const nextMove = {};
        if (typeof (cardIdx) === 'number' && cardIdx < deck.length) {
            nextMove.cardIdx = cardIdx;
            setPlayerPlayData({
                nextMove: {
                    cardNum: deck[cardIdx].cardNum,
                    direction: deck[cardIdx].direction
                }
            });
        }
        socket.emit('move', nextMove);
    }, [socket, deck]);

    const onAddAI = useCallback(() => {
        socket.emit('request_create_ai');
    }, [socket]);

    const onRemoveAI = useCallback(() => {
        socket.emit('request_remove_ai');
    }, [socket]);

    const onStart = useCallback(() => {
        socket.emit('request_game_start');
    }, [socket]);

    if (gameState === GAME_STATES.WAITING) {
        return (
            <GameWaiting
                players={waitingPlayers}
                playerStatePending={playerStatePending}
                gameWaitingTimer={gameWaitingTimer}
                minWaitingTime={minWaitingTime}
                requiredPlayers={requiredPlayers}
                onAddAI={onAddAI}
                onRemoveAI={onRemoveAI}
                onStart={onStart}
            />
        );
    }

    if (gameState === GAME_STATES.COMPLETED) {
        if (!result) {
            return <div>Loading the result...</div>;
        }

        return (
            <GameResult
                isEphemeral={isEphemeral}
                myPlayerTag={me && me.name}
                onPlayAgain={() => joinNewGame(!!result)}
                goToProfile={() => {
                    if (navigate) {
                        navigate('/profile');
                    }
                }}
                {...result}
            />
        );
    }

    return (
        <GameplayView
            gameState={gameState}
            cells={mergeArrays(board)}
            players={players}
            playerProfiles={playerProfiles}
            timeLeft={timeLeft}
            deck={deck}
            playerPlayData={playerPlayData}
            missions={missions}
            turnLength={turnLength}
            turn={turn}
            statusMessage={statusMessage}
            eventMessageQueue={eventMessageQueue}
            onCardSelect={onCardSelect}
            logMessages={logMessages}
            viewport={viewport}
            restartGame={() => joinNewGame(!!result)}
        />
    );
}

export function Gameplay() {
    const { gameId } = useParams();
    const [gameplayId, setGameplayId] = useState(uuid());
    const reset = useCallback(() => setGameplayId(uuid()), [setGameplayId]);

    useEffect(() => {
        setLocalConfig('skipHowTo', true);
    }, []);

    return (
        <UserTokenProvider>
            <UserDataProvider>
                <GameDataProvider gameId={gameId}>
                    <GameplayIO key={`gameplay-io-${gameplayId}`} gameId={gameId} reset={reset}>
                        <GameplayInner />
                    </GameplayIO>
                </GameDataProvider>
            </UserDataProvider>
        </UserTokenProvider>
    );
}
