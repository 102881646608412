import { GAME_ACTION_TYPES } from '../const';

export const INITIAL_STATE = {
    board: {
        width: null,
        height: null
    },
    viewport: null,
    waitingPlayers: [],
    players: [],
    eliminatedPlayerIds: [],
    newlyEliminated: [],
    deck: [],
    missions: {},
    timeLeft: 0,
    turnLength: 0,
    turn: -1,
    logMessages: []
};

export function applyPayloadToState(state, payload) {
    const {
        board, players, deck, game, turn, playerProfiles, viewport
    } = payload;

    const eliminatedPlayerIdsSet = new Set(state.eliminatedPlayerIds);
    const newEliminatedPlayerIds = [...state.eliminatedPlayerIds];

    players.forEach((player) => {
        if (player.isAlive || eliminatedPlayerIdsSet.has(player.id)) {
            return;
        }
        newEliminatedPlayerIds.push(player.id);
        player.justEliminated = true;
    });

    const newState = {
        board: {
            width: state.board.width,
            height: state.board.height,
            colors: board.colors,
            closed: board.closed,
            touched: board.touched,
            visibility: board.visibility
        },
        players,
        viewport,
        deck,
        turn,
        eliminatedPlayerIds: newEliminatedPlayerIds,
        missions: game.missions,
        turnLength: game.turnLength,
        archived: state.archived,
        logMessages: state.logMessages
    };

    // Some things can be updated only once at init
    if (state.turn === -1) {
        newState.board.width = board.width;
        newState.board.height = board.height;
        newState.playerProfiles = playerProfiles;
    }

    return newState;
}

export function gameReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case GAME_ACTION_TYPES.UPDATED:
        return applyPayloadToState(state, action.payload);
    case GAME_ACTION_TYPES.PREGAME_DATA_RECEIVED:
        return {
            ...state,
            board: action.data.board,
            playerProfiles: action.data.playerProfiles,
            players: action.data.players,
            missions: action.data.missions,
            deck: action.data.deck
        };
    case GAME_ACTION_TYPES.PLAYERS_UPDATED:
        return {
            ...state,
            waitingPlayers: action.players,
            // This is actually max waiting time in the backend services... but,
            // feel like this is more of "min" waiting time all of sudden...
            minWaitingTime: action.waitingTime,
            requiredPlayers: action.requiredPlayers
        };
    case GAME_ACTION_TYPES.TICK:
        return {
            ...state,
            timeLeft: action.timeLeft
        };
    case GAME_ACTION_TYPES.RESET:
        return {
            ...INITIAL_STATE
        };
    case GAME_ACTION_TYPES.LOG_MESSAGE_RECEIVED:
        return {
            ...state,
            logMessages: [...state.logMessages, { message: action.message, color: action.color }]
        };
    case GAME_ACTION_TYPES.GAME_WAITING_TIMER:
        return {
            ...state,
            gameWaitingTimer: action.gameWaitingTimer
        };
    default:
        return state;
    }
}
