import React, { useState, useCallback, useMemo, useEffect } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

import {
    PLAYER_TAG_PATTERN,
    PLAYER_TAG_RESTRICTION_MESSAGE,
    PLAYER_TAG_DUPLICATED_MESSAGE,
    PASSWORD_PATTERN,
    PASSWORD_RESTRICTION_MESSAGE,
} from '../utils/user';
import { Button } from './Button';
import { TextField } from './TextField';
import logo from '../assets/logo.svg';
import { Tile } from './Tile';

import './Register.scss';

export function Register({ inviteCode, onFinish }) {
    const [playerTag, setPlayerTag] = useState('');
    const [isPlayerTagValid, setIsPlayerTagValid] = useState(false);
    const [isPlayerTagDuplicated, setIsPlayerTagDuplicated] = useState(false);
    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [message, setMessage] = useState('');

    const validatePlayerTag = useCallback(debounce((playerTag) => {
        if (playerTag) {
            axios.get(`${process.env.REACT_APP_GAME_REST_URI}/users/player-tag?player_tag=${playerTag}`).then((response) => {
                if (response.data.exists === false) {
                    setIsPlayerTagDuplicated(false);
                } else {
                    setIsPlayerTagDuplicated(true);
                }
            });
        }
    }, 200), []);

    useEffect(() => {
        validatePlayerTag(playerTag);
    }, [playerTag, validatePlayerTag]);

    const onSubmit = useCallback(() => {
        if (!isPlayerTagValid || !isPasswordValid) {
            return;
        }
        // TODO: playerTag validation as validation rules finalized
        axios.post(`${process.env.REACT_APP_GAME_REST_URI}/users`, {
            playerTag,
            password,
            inviteCode
        }).then(() => axios.post(`${process.env.REACT_APP_GAME_REST_URI}/auth/token`, {
            player_tag: playerTag,
            password
        })).then((response) => {
            if (onFinish) {
                onFinish(JSON.stringify(response.data.token), response.data.user.id, response.data.user.player_tag);
            }
        }).catch(() => {
            setMessage('Register failed.');
        });
    }, [playerTag, password, isPlayerTagValid, isPasswordValid, onFinish]);
    const onChangePlayerTag = useCallback((e) => {
        const newPlayerTag = e.target.value;
        if (!newPlayerTag.match(PLAYER_TAG_PATTERN)) {
            setIsPlayerTagValid(false);
        } else {
            setIsPlayerTagValid(true);
        }
        setPlayerTag(newPlayerTag);
    }, [setPlayerTag]);
    const onChangePassword = useCallback((e) => {
        const newPassword = e.target.value;
        if (!newPassword.match(PASSWORD_PATTERN)) {
            setIsPasswordValid(false);
        } else {
            setIsPasswordValid(true);
        }
        setPassword(newPassword);
    }, [setPassword]);
    const buttonDisableMessage = useMemo(() => {
        const messages = [];
        if (!playerTag) {
            messages.push('Please enter player tag');
        } else if (!isPlayerTagValid) {
            messages.push(PLAYER_TAG_RESTRICTION_MESSAGE);
        }
        if (!password) {
            if (messages.length > 0) {
                messages.push(<br />);
            }
            messages.push('Please enter password');
        } else if (!isPasswordValid) {
            if (messages.length > 0) {
                messages.push(<br />);
            }
            messages.push(PASSWORD_RESTRICTION_MESSAGE);
        }
        if (isPlayerTagDuplicated) {
            if (messages.length > 0) {
                messages.push(<br />);
            }
            messages.push(PLAYER_TAG_DUPLICATED_MESSAGE);
        }
        return messages;
    }, [playerTag, password, isPlayerTagValid, isPlayerTagDuplicated, isPasswordValid]);
    // /game is currently the only page that guest should go after bypassing sign-in
    return (
        <Tile>
            <div className="register-box">
                <div className="heading">
                    <img src={logo} alt="" />
                    <div className="gamename">blend</div>
                    <div className="subtitle">Private Beta</div>
                </div>
                <div className="form">
                    <TextField label="Player Tag" id="player-tag" type="text" maxLength={16} value={playerTag} onChange={onChangePlayerTag} />

                    <TextField label="Password" id="password" type="password" maxLength={16} value={password} onChange={onChangePassword} />
                    <TextField label="Invite Code" id="invite-code" type="text" defaultValue={inviteCode} disabled />
                    {message && (
                        <div className="message">{message}</div>
                    )}
                </div>
                <div className="actions">
                    <Button label="Register" contentType="default" onClick={onSubmit} transparent disabled={!isPlayerTagValid || !isPasswordValid || isPlayerTagDuplicated} disableMessage={buttonDisableMessage} />
                </div>
            </div>
        </Tile>
    );
}
