import React from 'react';
import { isInRange } from './GameStat';

import './GameGlobalStatus.scss';

export function GameGlobalStatus({
    turn, players
}) {
    return (
        <div className="game-global-status">
            <div className="status-item">
                <span className="status-item-label">Turn</span>
                <span className="status-item-value">{turn}</span>
            </div>
            <div className="status-item">
                <span className="status-item-label">
                    <i className="bi bi-person-fill" />
                </span>
                <span className="status-item-value">{players.filter((player) => player.isAlive).length}/{players.length}</span>
            </div>
        </div>
    );
}

export function LegacyGameGlobalStatus({
    turn, players, objectiveStats
}) {
    const satisfied = objectiveStats && Object.keys(objectiveStats).filter((stat) => isInRange(objectiveStats[stat])).length;
    return (
        <div className="game-global-status">
            <div className="status-item">
                <span className="status-item-label">Turn</span>
                <span className="status-item-value">{turn >= 0 ? turn : ''}</span>
            </div>
            <div className="status-item">
                <span className="status-item-label">
                    <i className="bi bi-person-fill" />
                </span>
                <span className="status-item-value">{players.filter((player) => player.isAlive).length}/{players.length}</span>
            </div>
            {objectiveStats && (
                <div className="status-item">
                    <span className="status-item-label">Class</span>
                    <span className="status-item-value">{Object.keys(objectiveStats).length - satisfied + 1}</span>
                </div>
            )}
        </div>
    );
}
