import React from 'react';

import { toRGBString } from '../utils/color';

import './PlayerTag.scss';

export function PlayerTag({
    playerTag, color, dark, isAlive
}) {
    const classNames = ['player-tag-wrapper'];
    if (dark) {
        classNames.push('dark');
    }
    if (!isAlive) {
        classNames.push('eliminated');
    }
    return (
        <div className={classNames.join(' ')} style={{ backgroundColor: toRGBString(color) }}>
            <span className="player-tag">{playerTag}</span>
        </div>
    );
}
