import React from 'react';
import { useWindowResize } from '../hooks/useWindowResize';

const MIN_WIDTH = 600;
const MIN_HEIGHT = 480;
const MESSAGE_STYLE = { zIndex: 999, position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '24px', color: 'white'};

export function WindowSizeGuard({ children }) {
    const { windowWidth, windowHeight } = useWindowResize();
    const screenSizeSatisfied = (windowWidth >= MIN_WIDTH && windowHeight >= MIN_HEIGHT);
    return (
        <>
            {screenSizeSatisfied && children}
            {!screenSizeSatisfied && <div style={MESSAGE_STYLE}>Please use a device with bigger display size.</div>}
        </>
    );
}
