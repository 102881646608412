import React, { useState, useEffect } from 'react';

import { RequireAuthenticated } from '../contexts/userTokenContext';
import { UserDataProvider } from '../contexts/userDataContext';
import { TopNav } from '../components/TopNav';
import { NavButton } from '../components/NavButton';
import { DarkBackground } from '../components/DarkBackground';
import { HowToPlay } from '../components/HowToPlay';

import './HowTo.scss';

export function HowToInner() {
    return (
        <div className="main-container how-to">
            <div>
                <div className="how-to-inner">
                    <div className="header">
                        <div className="title">How to play</div>
                    </div>
                    <div className="tile-wrapper">
                        <HowToPlay />
                    </div>
                    <div className="actions">
                        <div className="play-button-wrapper">
                            <NavButton label="I'm ready!" to="/game" inverted />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-wrapper">
                <DarkBackground gradation squares />
            </div>
        </div>
    );
}

export function HowToPage(props) {
    return (
        <RequireAuthenticated to="center">
            <UserDataProvider>
                <TopNav />
                <HowToInner {...props} />
            </UserDataProvider>
        </RequireAuthenticated>
    );
}
