import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

import { RequireAuthenticated, UserTokenContext } from '../contexts/userTokenContext';
import { UserDataProvider } from '../contexts/userDataContext';
import { getLocalConfig } from '../utils/user';
import { TopNav } from '../components/TopNav';
import { NavButton } from '../components/NavButton';
import { Button } from '../components/Button';
import { Tile } from '../components/Tile';
import { ToneStack } from '../components/ToneStack';
import { BetaLeaderboard } from '../components/BetaLeaderboard';

import { HowToPlay } from '../components/HowToPlay';

import './Center.scss';

const STORY = (
    <>
        Welcome to Blend Closed Beta Service.
        Show us your best and make your team proud.
        Good luck.
    </>
);

export function CenterInner() {
    const { userToken } = useContext(UserTokenContext);
    const [user, setUser] = useState();
    const [netWorthStandings, setNetWorthStandings] = useState([]);
    const [tonesByTeam, setTonesByTeam] = useState([]);
    const [showHowToPopup, setShowHowToPopup] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (userToken) {
            axios.get(`${process.env.REACT_APP_GAME_REST_URI}/users`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            }).then((response) => {
                if (response.data) {
                    setUser(response.data.user);
                }
            });
        }
    }, [userToken]);

    useEffect(() => {
        const dataPromises = [
            axios.get(`${process.env.REACT_APP_GAME_REST_URI}/standings/net-worth`),
            axios.get(`${process.env.REACT_APP_GAME_REST_URI}/standings/tones-by-team`),
        ];
        Promise.all(dataPromises).then(([responseNetWorth, responseTonesByTeam]) => {
            if (responseNetWorth.data) {
                setNetWorthStandings(responseNetWorth.data.standing);
            }
            if (responseTonesByTeam.data) {
                setTonesByTeam(responseTonesByTeam.data);
            }
        });
    }, []);

    const requestNewGameId = useCallback(() => {
        return axios.post(`${process.env.REACT_APP_GAME_REST_URI}/games`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        }).then((response) => {
            if (response.data?.gameId) {
                return response.data.gameId;
            }
            throw 'Game could not be created';
        })
    }, [userToken]);

    return (
        <div className="main-container center">
            <div className="content">
                <Tile>
                    <div className="story">
                        {STORY}
                    </div>
                    <BetaLeaderboard netWorthStandings={netWorthStandings} tonesByTeam={tonesByTeam} myTeamId={user?.team_id} myId={user?.id} myPlayerTag={user?.player_tag} myPlayerProfile={user?.player_profile} />
                </Tile>
                <div className="menu">
                    <div className="button-wrapper">
                        <NavButton label="Play" to={getLocalConfig('skipHowTo') ? '/game' : '/how-to'} inverted />
                    </div>
                    <div className="button-wrapper">
                        {process.env.REACT_APP_FF_FRIENDLY_GAME && (
                            <Button label="Friendly" onClick={() => {
                                requestNewGameId().then((gameId) => {
                                    navigate(`/game/${gameId}`);
                                }).catch(() => {
                                    console.log('Game could not be created');
                                });
                            }} inverted />
                        )}
                    </div>
                    {getLocalConfig('skipHowTo') && <div className="button-wrapper">
                        <Button label="How To Play" onClick={() => { setShowHowToPopup(true); }} inverted />
                    </div>}
                </div>
            </div>
            {showHowToPopup && <div className="popup-wrapper">
                <div className="how-to-popup">
                    <HowToPlay />
                    <Button label="Got it!" onClick={() => { setShowHowToPopup(false); }} inverted />
                </div>
                <div className="how-to-backdrop" />
            </div>}
            <ToneStack />
        </div>
    );
}

export function Center(props) {
    return (
        <RequireAuthenticated to="center">
            <UserDataProvider>
                <TopNav />
                <CenterInner {...props} />
            </UserDataProvider>
        </RequireAuthenticated>
    );
}
