import React from 'react';

import { Tile } from '../components/Tile';
import Step1Image from '../assets/how-to-step-1.png';
import Step2Image from '../assets/how-to-step-2.png';
import Step3Image from '../assets/how-to-step-3.png';

import './HowToPlay.scss';

export function HowToPlay() {
    return (
        <div className="how-to-play">
            <Tile>
                <div className="content-wrapper">
                    <div className="step step-1">
                        <div className="text-block">
                            <span className="number">1.</span> Your objective is to <span className="highlighted">knockout other markers and Survive</span> until the last round.
                        </div>
                        <div className="image-block"><img src={Step1Image} width={180} /></div>
                    </div>
                    <div className="step step-2">
                        <div className="image-block"><img src={Step2Image} height={100} /></div>
                        <div className="text-block">
                        <span className="number">2.</span> <span className="highlighted">Choose your card</span>. Your card will have a direction. The arrow determines the <span className="highlighted">direction</span> of your marker at the end of every turn.
                        </div>
                    </div>
                    <div className="step step-3">
                        <div className="text-block"><span className="number">3.</span> The main number on your card shows your <span className="highlighted">Strength</span>; it's represented by 1 to 8.</div>
                        <div className="image-block"><img src={Step3Image} width={120} /></div>
                        <div className="text-block">At the end of a turn, if you and the other markers (players) collide, you can knock others off the board if you've chosen a card with a power <span className="highlighted">greater</span> than theirs.</div>
                    </div>
                </div>
            </Tile>
        </div>
    );
}
