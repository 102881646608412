import React from 'react';

import { StatDisplays } from '../utils/stats';
import avgEmission from '../assets/stat_icons/avg_emission.svg';
import avgSaturation from '../assets/stat_icons/avg_saturation.svg';
import leaderScore from '../assets/stat_icons/leader_score.svg';
import shapeComplexity from '../assets/stat_icons/shape_complexity.svg';
import shapeSymmetry from '../assets/stat_icons/shape_symmetry.svg';
import surfaceCoverage from '../assets/stat_icons/surface_coverage.svg';
import totalEmission from '../assets/stat_icons/total_emission.svg';
import colorVariance from '../assets/stat_icons/color_variance.svg';
import avgLightness from '../assets/stat_icons/average_lightness.svg';
import turns from '../assets/stat_icons/turns.svg';
import { getAccomplishStatus } from '../utils/mission';
import { Tile } from './Tile';

import './GameStatCard.scss';

const statIcons = {
    'shape_complexity': shapeComplexity,
    'shape_symmetry': shapeSymmetry,
    'surface_coverage': surfaceCoverage,
    'hv': colorVariance,
    'sm': avgSaturation,
    'lm': avgLightness,
    'winners_average_emission': avgEmission,
    'winners_total_emission': totalEmission,
    'winners_score': leaderScore,
    'game_length': turns // TODO change name to turns from statdisplays

};

export function GameStatCard({ statName, mission, size }) {
    // statName: string, stat: number, size: 'sm' | 'md' | 'lg'
    const { isAccomplished } = getAccomplishStatus(mission);

    return (
        <Tile>
            <div key={`stat-${statName}`} className={`game-stat-card ${size}${size !== 'sm' && isAccomplished ? ' accomplished' : ''}`}>
                {statIcons[statName] ? <img src={statIcons[statName]} alt="" /> : <div className="no-img" />}
                {size !== 'sm' && <div className="display-name">{StatDisplays[statName].displayName}</div>}
                <div className="stat-value">{StatDisplays[statName].formatter(mission.stat.value)}</div>
            </div>
        </Tile>
    );
}
