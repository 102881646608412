import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserItemsFromLocalStorage } from '../utils/user';

export const UserTokenContext = createContext();

export function RequireAuthenticated({ children, to }) {
    const [userToken, setUserToken] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        const { token } = getUserItemsFromLocalStorage();
        if (!token) {
            navigate({
                pathname: '/sign-in',
                search: `?to=${to}`
            });
        }
        setUserToken(token);
    }, [navigate, to]);

    return (
        <UserTokenContext.Provider value={{ userToken }}>
            {userToken && children}
        </UserTokenContext.Provider>
    );
}

export function UserTokenProvider({ children }) {
    const [userToken, setUserToken] = useState();
    const [userId, setUserId] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        const { token, userId } = getUserItemsFromLocalStorage();
        try {
            if (token) {
                // Additional validations come here.
                setUserToken(token);
                setUserId(userId);
            }
        } catch (ex) {
            localStorage.removeItem('ut');
        }
    }, [navigate, setUserToken, setUserId]);

    return (
        <UserTokenContext.Provider value={{ userToken, userId }}>
            {children}
        </UserTokenContext.Provider>
    );
}
