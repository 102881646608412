export const PLAYER_TAG_PATTERN = /[A-Za-z0-9-_.]{4,12}/;
export const PLAYER_TAG_RESTRICTION_MESSAGE = 'Player tag can contain 4 to 12 letters of alphabets, numbers, -, _ and .';
export const PLAYER_TAG_DUPLICATED_MESSAGE = 'Player tag already exists. Choose a different player tag';
export const PASSWORD_PATTERN = /.{3,64}/;
export const PASSWORD_RESTRICTION_MESSAGE = 'Password needs to be at least 3 letters long';

const LOCAL_CONFIG_VARIABLE = 'localConfig';

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64)
        .split('')
        .map((ch) => (`%${(`00${ch.charCodeAt(0).toString(16)}`).slice(-2)}`))
        .join(''));
    return JSON.parse(jsonPayload);
}

function isTokenExpired(token) {
    const decodedJwtPayload = parseJwt(token);
    return (!decodedJwtPayload.exp || (decodedJwtPayload.exp < Math.floor(Date.now() / 1000)));
}

export function setUserItemsToLocalStorage(token, userId, playerTag) {
    localStorage.setItem('ut', token);
    localStorage.setItem('ui', userId);
    localStorage.setItem('upt', playerTag);
}

export function getUserItemsFromLocalStorage() {
    const tokenString = localStorage.getItem('ut');
    if (!tokenString) {
        return {};
    }
    let token;
    try {
        token = JSON.parse(tokenString);
    } catch (ex) {
        console.warn('Invalid token: ', tokenString);
        clearUserItemsInLocalStorage();
        return {};
    }

    if (isTokenExpired(token)) {
        clearUserItemsInLocalStorage();
        return {};
    }

    return {
        token,
        userId: localStorage.getItem('ui'),
        playerTag: localStorage.getItem('upt')
    };
}

export function clearUserItemsInLocalStorage() {
    localStorage.removeItem('ut');
    localStorage.removeItem('ui');
    localStorage.removeItem('upt');
}

export function setLocalConfig(key, value) {
    let localConfig;
    const configString = localStorage.getItem(LOCAL_CONFIG_VARIABLE);
    if (!configString) {
        localConfig = {};
    } else {
        try {
            localConfig = JSON.parse(configString);
        } catch (ex) {
            localConfig = {};
        }
    }
    localConfig[key] = value;
    localStorage.setItem(LOCAL_CONFIG_VARIABLE, JSON.stringify(localConfig));
}

export function getLocalConfig(key) {
    const configString = localStorage.getItem(LOCAL_CONFIG_VARIABLE);
    if (!configString) {
        return;
    }
    try {
        const localConfig = JSON.parse(configString);
        if (localConfig[key]) {
            return localConfig[key];
        }
    } catch (ex) {
        return;
    }
    return;
}
