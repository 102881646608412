import React, { useMemo, useState } from 'react';

import { toneCodeToHSLColor } from '../utils/color';

import './TonePalette.scss';

export function TonePalette({ tones, bucketized, onSelect }) {
    const colors = useMemo(() => mapTones(tones), [tones]);
    const bucketizedColors = useMemo(() => bucketizeColors(colors), [colors]);

    if (colors.length === 0) {
        return <div />;
    }
    if (bucketized) {
        return <BucketizedPalette buckets={bucketizedColors} />;
    }

    return <Palette colors={colors} onSelect={onSelect} />;
}

export function Palette({ colors, onSelect }) {
    const [selected, setSelected] = useState();
    return (
        <div className="tone-palette">
            {colors.map((color) => (
                <div
                    key={`color-${color.toneCode}`}
                    className={`color${color.toneCode === selected ? ' selected' : ''}`}
                    style={{
                        backgroundColor: `hsl(${color.hsl[0]}deg ${color.hsl[1]}% ${color.hsl[2]}%)`
                    }}
                    onClick={() => {
                        setSelected(color.toneCode);
                        if (onSelect) {
                            onSelect(color.toneCode);
                        }
                    }}
                >
                    <div className="count">{color.count}</div>
                </div>
            ))}
        </div>
    );
}

export function BucketizedPalette({ buckets }) {
    return (
        <div className="tone-palette bucketized">
            {buckets.map((colorBucket) => (
                <div
                    key={`color-bucket-from-${colorBucket[0].hsl[0]}`}
                    className="color-column"
                >
                    {colorBucket.map((color) => {
                        const toneTiles = [];
                        const fadeInClass = `fade-in-${parseInt(Math.random() * 100)}`;
                        for (let i = 0; i < color.count; i++) {
                            toneTiles.push(
                                <div
                                    key={`tone-${color.toneCode}-${i}`}
                                    className={`tone ${fadeInClass}`}
                                    style={{
                                        backgroundColor: `hsl(${color.hsl[0]}deg ${color.hsl[1]}% ${color.hsl[2]}%)`
                                    }}
                                />
                            );
                        }
                        if (toneTiles.length === 1) {
                            return toneTiles;
                        }
                        return <div key={`tone-group-${color.toneCode}`} className={`same-tone ${fadeInClass}`}>{toneTiles}</div>;
                    })}
                </div>
            ))}
        </div>
    );
}

function mapTones(tones) {
    return Object.keys(tones).map((toneCode) => ({
        toneCode,
        count: tones[toneCode],
        hsl: toneCodeToHSLColor(toneCode)
    })).sort(compareColors);
}

function bucketizeColors(colors) {
    if (colors.length === 0) {
        return [];
    }
    const hueSpan = Math.abs(colors[0].hsl[0] - colors[colors.length - 1].hsl[0]);
    const bucketCount = Math.max(parseInt(Math.sqrt(colors.length) + 1), 30);
    const bucketSpan = hueSpan / bucketCount;

    const buckets = {};
    colors.forEach((color) => {
        const bucketKey = parseInt(color.hsl[0] / bucketSpan);
        if (!buckets[bucketKey]) {
            buckets[bucketKey] = [];
        }
        buckets[bucketKey].push(color);
    });
    const sortedBucketKeys = Object.keys(buckets).sort();

    return sortedBucketKeys.map((bucketKey) => buckets[bucketKey]);
}

function compareColors(colorA, colorB) {
    if (colorA.hsl[0] > colorB.hsl[0]) {
        return -1;
    }
    if (colorA.hsl[0] < colorB.hsl[0]) {
        return 1;
    }
    if (colorA.hsl[2] > colorB.hsl[2]) {
        return -1;
    }
    if (colorA.hsl[2] < colorB.hsl[2]) {
        return 1;
    }
    if (colorA.hsl[1] > colorB.hsl[1]) {
        return -1;
    }
    return 1;
}
