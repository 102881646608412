import React, {
    useState, useContext, useMemo, useEffect, useRef
} from 'react';

import { GameplayDataProvider, GameplayContext } from '../contexts/GameplayContext';
import {
    useGameSize, VIEW_MODE, GAME_TOP_HEIGHT, GAME_BOTTOM_HEIGHT
} from '../hooks/useGameSize';
import { useWindowResize } from '../hooks/useWindowResize';
import { toRGBString } from '../utils/color';
import { toPlayerListItem, sortPlayersByPointDesc } from '../utils/game';
import { GAME_STATES } from '../const';

import { Board } from './Board';
import { PlayerList } from './PlayerList';
import { PlayerTag } from './PlayerTag';
import { Deck } from './Deck';
import { TimeBar } from './TimeBar';
import { Button } from './Button';
import { GameGlobalStatus } from './GameGlobalStatus';
import { GameMissions } from './GameMissions';
import { GameStatusMessage } from './GameStatusMessage';
import { KnockoutBonus } from './KnockoutBonus';
import { GameReward } from './GameReward';
import { JoyrideTour } from './JoyrideTour';
import { Confirmation } from './Confirmation';

import './GameplayView.scss';

const SIDEBAR_RIGHT_WIDTH = 200;
const MIN_GAME_SIZE = 300;
const GAME_PADDING = 50;

const steps = [
    {
        target: '.game-reward .class-num', content: 'The reward is decided based on this class.', disableBeacon: true, placement: 'left'
    },
    {
        target: '.new-board', content: 'The board will glow depending on the game class', disableBeacon: true, placement: 'right'
    },
    {
        target: '.game-missions .missions', content: 'which stands for the number of these missions accomplished.', disableBeacon: true, placement: 'right'
    },
    {
        target: '.game-reward', content: 'Then, this table will give you a hint how much reward you will get.', disableBeacon: true, placement: 'left'
    },
    {
        target: '.control-area', content: 'Start making your moves by choosing a card.', disableBeacon: true, placement: 'top'
    }
];

export function GameplayView({
    gameState, cells, players, playerProfiles, playerPlayData, deck, onCardSelect, turnLength, timeLeft, missions, statusMessage, eventMessageQueue, turn, restartGame, viewport
}) {
    const me = useMemo(() => players.find((player) => player.isMe), [players]);
    const [tourSteps] = useState(steps);

    return (
        <GameplayDataProvider gameState={gameState} missions={missions} cells={cells} viewport={viewport} players={players} playerPlayData={playerPlayData} statusMessage={statusMessage} eventMessageQueue={eventMessageQueue} turn={turn} playerProfiles={playerProfiles} startTutorial={() => { setRunTour(true); }} stopTutorial={() => { setRunTour(false); }}>
            <div className="gameplay">
                <ContentArea />
                <ControlArea deck={me && me.isAlive && deck} onCardSelect={onCardSelect} turnLength={turnLength} timeLeft={timeLeft} myColor={me?.color} restartGame={restartGame} gameState={gameState} />
            </div>
            <JoyrideTour steps={tourSteps} shouldRun={gameState === GAME_STATES.PREGAME} />
        </GameplayDataProvider>
    );
}

// Player list, rankings
function SidebarLeft({ width }) {
    const [isPlayerListCollapsed, setIsPlayerListCollapsed] = useState(false);
    const {
        players, me, gameState, playerProfiles
    } = useContext(GameplayContext);

    const playerListItems = useMemo(() => players.map((player) => toPlayerListItem(player, me, playerProfiles)).sort(sortPlayersByPointDesc), [players]);
    useEffect(() => {
        if (width < 180) {
            setIsPlayerListCollapsed(true);
        } else {
            setIsPlayerListCollapsed(false);
        }
    }, [width]);

    return (
        <div className="sidebar-left">
            <PlayerList players={playerListItems} collapsed={isPlayerListCollapsed} toggleCollapsed={() => setIsPlayerListCollapsed((oldValue) => !oldValue)} entering={gameState === GAME_STATES.PREGAME} />
        </div>
    );
}

// Reward related information
function SidebarRight({ width, contentHeight }) {
    const {
        rewardInfo, players, missions, gameState, me, playerProfiles
    } = useContext(GameplayContext);

    const knockouts = useMemo(() => {
        const knockoutPlayerIds = me?.knockoutPlayerIds || [];
        return knockoutPlayerIds.map((knockoutPlayerId) => {
            const knockoutPlayer = players.find((player) => (player.id === knockoutPlayerId));
            if (!knockoutPlayer) {
                return null;
            }
            const level = playerProfiles?.[knockoutPlayerId]?.level || 1;
            return {
                playerTag: knockoutPlayer.name,
                color: knockoutPlayer.color,
                colorMode: knockoutPlayer.colorMode,
                level
            };
        }).filter((knockout) => !!knockout);
    }, [me, players, playerProfiles]);

    return (
        <div className="sidebar-right" style={{height: `${contentHeight}px`}}>
            {missions && <GameMissions missions={missions} width={width - 16} />}
            {knockouts.length > 0 && <KnockoutBonus knockouts={knockouts} />}
            {rewardInfo && (
                <GameReward
                    percent={parseInt((rewardInfo.share.share) * 100)}
                    colored={rewardInfo.total.base.cells}
                    classNum={rewardInfo.total.factor.class}
                    rank={rewardInfo.share.myPoint.ranking}
                    defeat={rewardInfo.share.myPoint.defeated}
                    flag={rewardInfo.share.myPoint.flagged}
                    totalPlayer={players.length}
                    allocatedWidth={width}
                    knockouts={knockouts}
                />
            )}
        </div>
    );
}

function viewportCellsConverter(cells, from, to) {
    const newCells = [];
    for (let j = 0; j < cells.length; j += 1) {
        newCells.push([]);
        for (let i = 0; i < cells[0].length; i += 1) {
            newCells[j].push(cells[j][i]);
        }
    }
    
    return newCells;
}

function viewportPlayersConverter(players, from, to) {
    const newPlayers = players.map((player) => {
        const newPlayer = { ...player };
        if (newPlayer.x !== null && newPlayer.y !== null) {
            const inViewport = (newPlayer.x >= from.x && newPlayer.x <= to.x
                && newPlayer.y >= from.y && newPlayer.y <= to.y);
            if (!inViewport) {
                newPlayer.x = null;
                newPlayer.y = null;
            } else {
                newPlayer.x -= from.x;
                if (newPlayer.prevX !== null) {
                    newPlayer.prevX -= from.x;
                }
                newPlayer.y -= from.y;
                if (newPlayer.prevY !== null) {
                    newPlayer.prevY -= from.y;
                }
            }
        }
        return newPlayer;
    });
    return newPlayers;
}

function BoardWithViewport({ cells, viewport, players, ...args }) {
    const prevViewport = useRef(null);
    const prevEdge = useRef({ l: null, u: null, d: null, r: null });
    
    const { viewportedCells, viewportedPlayers } = useMemo(() => {
        if (!viewport) {
            return {
                viewportedCells: cells,
                viewportedPlayers: players,
            };
        }
        // For now, viewportCellsConverter simply clones the cells array
        const convertedCells = viewportCellsConverter(cells, viewport.from, viewport.to);
        const convertedPlayers = viewportPlayersConverter(players, viewport.from, viewport.to);

        // Save previous states
        prevViewport.current = viewport;
        prevEdge.current = { l: [], u: [], d: [], r: [] };
        for (let j = viewport.from.y; j <= viewport.to.y; j += 1) {
            prevEdge.current.l.push(convertedCells[j][viewport.from.x]);
            prevEdge.current.r.push(convertedCells[j][viewport.to.x]);
        }
        for (let i = viewport.from.x; i <= viewport.to.x; i += 1) {
            prevEdge.current.u.push(convertedCells[viewport.from.y][i]);
            prevEdge.current.d.push(convertedCells[viewport.to.y][i]);
        }
        
        return {
            viewportedCells: convertedCells,
            viewportedPlayers: convertedPlayers,
        };
    }, [cells, viewport]);

    return (
        <Board {...args} cells={viewportedCells} players={viewportedPlayers} origin={viewport ? viewport.from : null} viewport={viewport ? viewport : null} />
    );
}

function ContentArea() {
    const {
        cells, viewport, players, me, playerPlayData, statusMessage, eventMessageQueue, rewardInfo, turn, gameState, missions
    } = useContext(GameplayContext);
    const {
        viewMode, gameWidthPx, gameHeightPx, sideAvailableWidth
    } = useGameSize();
    const boardWidth = useMemo(() => Math.max(gameWidthPx - (GAME_PADDING * 2), MIN_GAME_SIZE), [gameWidthPx, viewMode]);

    let boardComponent;
    if (cells && cells.length) {
        if (viewport) {
            boardComponent = <BoardWithViewport boardWidth={boardWidth} boardHeight={boardWidth} cells={cells} players={players} viewport={viewport} playerPlayData={playerPlayData} gameClass={rewardInfo?.total.factor.class} entering={gameState === GAME_STATES.PREGAME} />;
        } else {
            boardComponent = <Board boardWidth={boardWidth} boardHeight={boardWidth} cells={cells} players={players} playerPlayData={playerPlayData} gameClass={rewardInfo?.total.factor.class} entering={gameState === GAME_STATES.PREGAME} />
        }
    }

    let content;
    if (viewMode === VIEW_MODE.LANDSCAPE) {
        content = (
            <>
                <div className="sidebar-left-wrapper" style={{ minWidth: `${SIDEBAR_RIGHT_WIDTH}px` }}>
                    <SidebarLeft width={sideAvailableWidth} />
                </div>
                <div className="game" style={{ width: `${boardWidth}px`, height: '100%' }}>
                    <div className="game-info" style={{ height: `${GAME_TOP_HEIGHT}px` }}>
                        <GameGlobalStatus turn={turn} players={players} />
                    </div>
                    {boardComponent}
                    <div className="player-info" style={{ height: `${GAME_BOTTOM_HEIGHT}px` }}>
                        {me && <PlayerTag playerTag={me ? me.name : 'Unknown Player'} color={me ? me.color : null} dark={me && me.colorMode === 'dark'} isAlive={me.isAlive} />}
                        <GameStatusMessage statusMessage={statusMessage} incomingQueue={eventMessageQueue} />
                    </div>
                </div>
                <div className="sidebar-right-wrapper" style={{ minWidth: `${SIDEBAR_RIGHT_WIDTH}px` }}>
                    <SidebarRight width={sideAvailableWidth} contentHeight={boardWidth + GAME_TOP_HEIGHT + GAME_BOTTOM_HEIGHT}/>
                </div>
            </>
        );
    } else if (viewMode === VIEW_MODE.PORTRAIT) {
        // Sidebars are not going to shown until we come up with vertical mode of each
        content = (
            <div className="game" style={{ width: '100%', height: `${gameHeightPx}px` }}>
                <div className="game-info" style={{ height: `${GAME_TOP_HEIGHT}px` }}>
                    <GameGlobalStatus turn={turn} players={players} />
                </div>
                {boardComponent}
                <div className="player-info" style={{ height: `${GAME_BOTTOM_HEIGHT}px` }}>
                    {me && <PlayerTag playerTag={me ? me.name : 'Unknown Player'}  color={me ? me.color : null} dark={me && me.colorMode === 'dark'} isAlive={me.isAlive} />}
                    <GameStatusMessage statusMessage={statusMessage} incomingQueue={eventMessageQueue} />
                </div>
            </div>
        );
    }

    return (
        <div className="content-area" style={{gridTemplateColumns: `1fr ${boardWidth}px 1fr`}}>
            {content}
        </div>
    );
}

function ControlArea({
    deck, onCardSelect, turnLength, timeLeft, myColor, gameState, restartGame
}) {
    const { rewardInfo } = useContext(GameplayContext);
    const {
        windowWidth
    } = useWindowResize();

    const [showExitConfirmation, setShowExitConfirmation] = useState(false);
    const exitConfirmMessage = useMemo(() => (rewardInfo ? `Would you really give up predicted reward of ${parseFloat(rewardInfo.total.base.cells*rewardInfo.share.share).toFixed(2)} Tones to play another game?` : null), [rewardInfo]);

    return (
        <div className="control-area">
            <TimeBar total={turnLength} current={timeLeft} barColor={myColor ? toRGBString(myColor) : null} />
            {deck && <Deck deck={deck} onCardSelect={onCardSelect} widgetWidth={windowWidth} />}
            {(!deck && gameState === GAME_STATES.INGAME) && (
                <div id="eliminate-message">
                    <span>Stay on to receive rewards, or </span>
                    <Button label="Play Another Game" onClick={() => {
                        if (exitConfirmMessage) {
                            setShowExitConfirmation(true);   
                        } else {
                            restartGame();
                        }
                    }} />
                    {showExitConfirmation && <Confirmation message={exitConfirmMessage} onConfirm={() => { restartGame(); }} onCancel={() => { setShowExitConfirmation(false); }}/>}
                </div>
            )}
        </div>
    );
}
