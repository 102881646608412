import React, { useEffect, useState, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import { toRGBString } from '../utils/color';

import './KnockoutBonus.scss';

import iconDefeat from '../assets/icon_defeat.svg';

/**
 * Shows knockout bonus
 * @param {{playerTag, color, level}} knockoutPlayers
 */
export function KnockoutBonus({ knockouts }) {
    const [appearedKnockouts, setAppearedKnockouts] = useState([]);

    useEffect(() => {
        const appearInterval = setInterval(() => {
            if (appearedKnockouts.length === knockouts.length) {
                clearInterval(appearInterval);
                return;
            }
            const notAppeared = knockouts.find((knockout) => {
                const appearedPlayerTags = new Set(appearedKnockouts.map((ko) => ko.playerTag));
                if (!appearedPlayerTags.has(knockout.playerTag)) {
                    return true;
                }
                return false;
            });
            setAppearedKnockouts([...appearedKnockouts, notAppeared]);
        }, 500);
        return () => {
            clearInterval(appearInterval);
        };
    }, [knockouts, appearedKnockouts]);

    const knockoutTones = useMemo(() => appearedKnockouts.map((knockout) => (
        <>
            <div key={`knockout-bonus-tone-${knockout.playerTag}`} className={`knockout-bonus-tone${knockout.colorMode === 'dark' ? ' dark' : ''}`} style={{ backgroundColor: toRGBString(knockout.color) }} data-tip data-for={`knockout-bonus-${knockout.playerTag}`}>x{knockout.level}</div>
            <ReactTooltip id={`knockout-bonus-${knockout.playerTag}`} place="top">{knockout.playerTag}</ReactTooltip>
        </>
    )), [appearedKnockouts]);
    return (
        <div className="knockout-bonus">
            <div className="title"><img src={iconDefeat} /> Bonus</div>
            <div className="knockout-tones">{knockoutTones}</div>
        </div>
    );
}
