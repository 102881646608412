import React from 'react';
import './LevelTag.scss';

export function LevelTag({ small, level }) {
    return (
        <div className={`level-tag${small ? ' small' : ''}`}>
            {!small && <span className="level-prefix">Lv. </span>}
            <span className="level-value">{level}</span>
        </div>
    );
}
