import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    PLAYER_TAG_PATTERN,
    PLAYER_TAG_RESTRICTION_MESSAGE,
    PASSWORD_PATTERN,
    PASSWORD_RESTRICTION_MESSAGE,
    setUserItemsToLocalStorage
} from '../utils/user';
import { Button } from './Button';
import { TextField } from './TextField';
import logo from '../assets/logo.svg';
import { Tile } from './Tile';

import './SignIn.scss';

export function SignIn({ to, allowGuest }) {
    const [playerTag, setPlayerTag] = useState('');
    const [isPlayerTagValid, setIsPlayerTagValid] = useState(false);
    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          onSubmit(event);
        }
      };
    const onSubmit = useCallback(() => {
        if (!isPlayerTagValid || !isPasswordValid) {
            return;
        }
        // TODO: playerTag validation as validation rules finalized
        axios.post(`${process.env.REACT_APP_GAME_REST_URI}/auth/token`, {
            player_tag: playerTag,
            password
        }).then((response) => {
            setUserItemsToLocalStorage(JSON.stringify(response.data.token), response.data.user.id, response.data.user.player_tag);
            navigate(`${to}`);
        }).catch((err) => {
            if (err.response.status === 401) {
                setMessage('PlayerTag/password do not match.');
            } else if (err.response.status === 400) {
                setMessage('PlayerTag or password is invalid.');
            }
        });
    }, [playerTag, password, isPlayerTagValid, isPasswordValid, navigate, to]);
    const onChangePlayerTag = useCallback((e) => {
        const newPlayerTag = e.target.value;
        if (!newPlayerTag.match(PLAYER_TAG_PATTERN)) {
            setIsPlayerTagValid(false);
        } else {
            setIsPlayerTagValid(true);
        }
        setPlayerTag(newPlayerTag);
    }, [setPlayerTag]);
    const onChangePassword = useCallback((e) => {
        const newPassword = e.target.value;
        if (!newPassword.match(PASSWORD_PATTERN)) {
            setIsPasswordValid(false);
        } else {
            setIsPasswordValid(true);
        }
        setPassword(newPassword);
    }, [setPassword]);
    const buttonDisableMessage = useMemo(() => {
        const messages = [];
        if (!playerTag) {
            messages.push('Please enter player tag');
        } else if (!isPlayerTagValid) {
            messages.push(PLAYER_TAG_RESTRICTION_MESSAGE);
        }
        if (!password) {
            if (messages.length > 0) {
                messages.push(<br />);
            }
            messages.push('Please enter password');
        } else if (!isPasswordValid) {
            if (messages.length > 0) {
                messages.push(<br />);
            }
            messages.push(PASSWORD_RESTRICTION_MESSAGE);
        }
        return messages;
    }, [playerTag, password, isPlayerTagValid, isPasswordValid]);
    // /game is currently the only page that guest should go after bypassing sign-in
    const onGuestEnter = () => navigate('/game');
    return (
        <Tile>
            <div className="sign-in-box">
                <div className="heading">
                    <img src={logo} alt="" />
                    <div className="gamename">blend</div>
                    <div className="subtitle">Private Beta</div>
                </div>
                <div className="form">
                    <TextField autoFocus label="Player Tag" id="player-tag" type="text" maxLength={16} value={playerTag} onChange={onChangePlayerTag} />

                    <TextField label="Password" id="password" type="password" maxLength={16} value={password} onChange={onChangePassword} onKeyDown={handleKeyPress} />
                    {/* TODO: Return Key */}
                    {/* <input id="password" type="password" maxLength="16" value={password} onChange={onChangePassword} onKeyUp={(e) => { if (e.key === 'Enter') { onSubmit(); } }} /> */}
                    {message && (
                        <div className="message">{message}</div>
                    )}
                </div>
                <div className="actions">
                    <Button label="Enter" contentType="default" onClick={onSubmit} disabled={!isPlayerTagValid || !isPasswordValid} disableMessage={buttonDisableMessage} />
                    {allowGuest && <Button contentType="textOnly" label="Enter as guest" onClick={onGuestEnter} inverted />}
                </div>
            </div>
        </Tile>
    );
}
