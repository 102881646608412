import React, { useMemo, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { UserTokenContext } from '../contexts/userTokenContext';
import { GameDataContext } from '../contexts/GameDataContext';

import { RotatingSections } from './RotatingSections';
import { VerticalTimer } from './VerticalTimer';
import { LevelTag } from './LevelTag';
import { DarkBackground } from './DarkBackground';
import { Button } from './Button';

import logo from '../assets/logo.svg';
import './GameWaiting.scss';
// Players list
// Time elapsed
// Game Setting

function PlayerWaitingTag({ name, player_profile: playerProfile, is_me: isMe }) {
    const classes = ['player', 'in'];
    if (isMe) {
        classes.push('me');
    }
    return (
        <div className={classes.join(' ')}>
            {playerProfile && <div className="tag-wrapper"><LevelTag level={playerProfile.level} /></div>}
            <span className="player-tag">{name}</span>
            {playerProfile && (
                <>
                    <i className="bi bi-boxes" />
                    <span className="tones">{playerProfile.total_tones}</span>
                </>
            )}
        </div>
    );
}

export function GameWaiting({
    players, playerStatePending, gameWaitingTimer, minWaitingTime, requiredPlayers, onAddAI, onRemoveAI, onStart
}) {
    const navigate = useNavigate();
    const { userId } = useContext(UserTokenContext);
    const { game } = useContext(GameDataContext);
    const isAdmin = useMemo(() => {
        if (!userId || !game?.ownedBy) {
            return false;
        }
        return userId === game.ownedBy;
    }, [userId, game]);
    const hintSection = useMemo(() => (
        <RotatingSections key="rotating-hint">
            <p>
                Welcome to BLND.<br />
                <br />
                <b>Primary Objective:</b><br />
                <b>Explore</b> and color the Canvas. <br />
                <b>Blend</b> your color with other's. <br /> 
                <b>Create and Collect</b> as many Tones as possible! <br />
            </p>
            <p>
                Those who can <b>Survive</b> the longest will receive <br />
                the most Tones!
            </p>
            <p>
                Try to claim as many empty Cells as possible <br />
                to gather more points. <br />
            </p>
            <p>
                When you step over a colored cell, your color will be <br />
                blended with the underlying color. <br />
                <h4>These Cells will are converted to <b>Tones</b>, your reward.</h4>
            </p>

        </RotatingSections>
    ), []);
    const onClickLogo = useCallback(() => {
        navigate('/');
    });
    let statusMessage = 'Waiting for minimum required players';
    if (playerStatePending) {
        statusMessage = 'Loading';
    } else if (requiredPlayers && players.length >= requiredPlayers) {
        statusMessage = 'Including more players';
    }
    return (
        <div className="game-waiting">
            <div className="top-nav-left">
                <img src={logo} id="logo" alt="logo" onClick={onClickLogo} />
            </div>
            <section className="waiting-hint">
                {hintSection}
                {minWaitingTime && typeof gameWaitingTimer === 'number'
                    ? <VerticalTimer key="timer-tick" tick={gameWaitingTimer} length={minWaitingTime} width={8} />
                    : <VerticalTimer key="timer-hold" interval={1500} width={8} />}
            </section>
            <section className="waiting-players">
                <div className="players-inner">
                    <div className="heading">
                        <span className="status-message">{statusMessage}</span>
                        <span className="blinker">...</span>
                        {requiredPlayers && players.length < requiredPlayers && <span className="player-count">{players.length}/{requiredPlayers}</span>}
                    </div>
                    <div className="players">
                        {typeof gameWaitingTimer === 'number' && players.map((player) => (<PlayerWaitingTag key={player.id} {...player} />))}
                    </div>
                </div>
                <div className="bg-wrapper">
                    <DarkBackground gradation squares />
                </div>
            </section>
            {isAdmin && (
                <div className="floating-menu">
                    <Button label="Add AI" onClick={onAddAI} transparent />
                    <Button label="Remove AI" onClick={onRemoveAI} transparent />
                    <Button label="Start" onClick={onStart} disabled={players.length < 2} />
                </div>
            )}
        </div>
    );
}
