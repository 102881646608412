import React, {
    useEffect, useState, useCallback, useContext
} from 'react';
import { useNavigate } from 'react-router';

import { getUserItemsFromLocalStorage, clearUserItemsInLocalStorage } from '../utils/user';
import { IconMenu } from './IconMenu';
import { Button } from './Button';
import logo from '../assets/logo.svg';

import './TopNav.scss';
import { Tile } from './Tile';

const LOGIN_ICON_CLASS = 'bi-person';
const LOGOUT_ICON_CLASS = 'bi-box-arrow-right';

export function TopNavView({
    playerTag,
    onClickLogo,
    onClickPlayerTag,
    onClickLogin,
    onClickLogout
}) {
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);

    let menuItems;
    if (playerTag) {
        menuItems = (
            <>
                <Button contentType="textOnly" label={playerTag} onClick={onClickPlayerTag} />
                <IconMenu menuItems={[{
                    iconClass: LOGOUT_ICON_CLASS,
                    onClick: () => setShowLogoutPopup((old) => !old),
                    isLocked: false
                }]}
                />
                {showLogoutPopup && (
                    <div className="logout-popup">
                        <Tile>
                            <div className="logout-popup-content">
                                <div className="message">Signing out?</div>
                                <div className="button-row">
                                    <Button contentType="iconOnlyAccent" icon="x-lg" onClick={() => setShowLogoutPopup(false)} inverted transparent />
                                    <Button contentType="iconOnlyAccent" icon="box-arrow-right" onClick={onClickLogout} inverted transparent />
                                </div>
                            </div>
                        </Tile>
                    </div>
                )}
            </>
        );
    } else {
        menuItems = (
            <IconMenu menuItems={[{
                iconClass: LOGIN_ICON_CLASS,
                onClick: onClickLogin,
                isLocked: false
            }]}
            />
        );
    }

    return (
        <>
            <div className="top-nav-left">
                <img src={logo} id="logo" alt="logo" onClick={onClickLogo} />
            </div>
            <div className="top-nav-right">
                {menuItems}
            </div>
        </>
    );
}

export function TopNav() {
    const navigate = useNavigate();
    const [playerTag, setPlayerTag] = useState();

    useEffect(() => {
        const { token, playerTag: storedPlayerTag } = getUserItemsFromLocalStorage();
        if (!token) {
            return;
        }
        setPlayerTag(storedPlayerTag);
    }, []);

    const onClickLogo = useCallback(() => {
        navigate('/');
    });

    const onClickPlayerTag = useCallback(() => {
        navigate('/profile');
    });

    const onClickLogin = useCallback(() => {
        navigate('/');
    }, [playerTag]);

    const onClickLogout = useCallback(() => {
        if (playerTag) {
            clearUserItemsInLocalStorage();
            navigate('/');
        }
    }, [playerTag]);

    return (
        <TopNavView
            playerTag={playerTag}
            onClickPlayerTag={onClickPlayerTag}
            onClickLogo={onClickLogo}
            onClickLogin={onClickLogin}
            onClickLogout={onClickLogout}
        />
    );
}
