import React from 'react';
import ReactTooltip from 'react-tooltip';

import './Lockable.scss';

export function Lockable({
    isLocked, lockMessage, inline, children
}) {
    const style = inline ? { display: 'inline-block' } : null;

    if (!isLocked) {
        return (
            <div className="lockable" style={style}>
                {children}
            </div>
        );
    }

    return (
        <>
            <div className="lockable locked" style={style} data-tip={lockMessage}>
                <div style={{ pointerEvents: 'none' }}>{children}</div>
            </div>
            <ReactTooltip />
        </>
    );
}
