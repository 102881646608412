import React, {
    createContext, useEffect, useState, useCallback, useContext
} from 'react';
import axios from 'axios';

import { UserTokenContext } from './userTokenContext';

export const GameDataContext = createContext();

export function GameDataProvider({ gameId, children }) {
    const { userToken } = useContext(UserTokenContext);
    const [game, setGame] = useState();
    
    useEffect(() => {
        if (gameId && userToken) {
            axios.get(`${process.env.REACT_APP_GAME_REST_URI}/games/${gameId}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            }).then((response) => {
                if (response.data.game) {
                    setGame(response.data.game);
                }
            });
        }
    }, [gameId, userToken]);

    return (
        <GameDataContext.Provider value={{ game }}>
            {children}
        </GameDataContext.Provider>
    );
}
