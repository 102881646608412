import React from 'react';

import { TextField } from './TextField';
import logo from '../assets/logo.svg';
import { Tile } from './Tile';

import './SignIn.scss';

export function BetaThanks() {
    return (
        <Tile>
            <div className="sign-in-box">
                <div className="heading">
                    <img src={logo} alt="" />
                    <div className="gamename">blend</div>
                    <div className="subtitle">Private Beta</div>
                </div>
                <div className="message">
                    Blend Beta Test Program<br />has been completed.<br /><br />
                    Thank you for your participation.
                </div>
            </div>
        </Tile>
    );
}
