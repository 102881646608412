import React, { useMemo } from 'react';

import './TimeBar.scss';

export function TimeBar({ total, current, barColor = '#222' }) {
    const beats = useMemo(() => {
        const timeBeats = [];
        const elapsed = total - current;
        for (let i = 0; i < total - 1; i++) {
            let cn = 'time-beat';
            if (current !== 0 && i < elapsed) {
                cn += ' elapsed';
            }
            timeBeats.push(
                <div key={`time-beat-${i}`} className={cn} style={{ backgroundColor: barColor }}>
                    <div className="time-beat-box" />
                </div>
            );
        }
        return timeBeats;
    }, [total, current, barColor]);

    return (
        <div className="time-bar">
            {beats}
        </div>
    );
}
