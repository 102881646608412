import React, { useMemo, useLayoutEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useWindowResize = () => {
    const [windowWidth, setWindowWidth] = useState();
    const [windowHeight, setWindowHeight] = useState();

    const getWindowSize = useMemo(() => () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    }, [setWindowHeight, setWindowWidth]);

    useLayoutEffect(() => {
        if (getWindowSize) {
            getWindowSize();
            window.addEventListener('resize', debounce(getWindowSize, 50));
            return () => {
                window.removeEventListener('resize', debounce(getWindowSize, 50));
            };
        }
        return null;
    }, [getWindowSize]);

    return { windowWidth, windowHeight };
};
