import React, { useMemo } from 'react';

import { toRGBString } from '../utils/color';
import { LevelTag } from './LevelTag';
import { Button } from './Button';

import './PlayerList.scss';

export function PlayerList({
    players, collapsed, toggleCollapsed, entering
}) {
    const wrapperClassName = useMemo(() => {
        const classes = ['player-list'];
        if (collapsed) {
            classes.push('collapsed');
        }
        if (entering) {
            classes.push('entering');
        }
        return classes.join(' ');
    }, [collapsed, entering]);

    const sortedPlayers = useMemo(() => players.sort((a, b) => a.ranking - b.ranking), [players]);

    return (
        <div className={wrapperClassName}>
            <div className="btn-row">
                <Button contentType="iconOnly" icon={`chevron-double-${!collapsed ? 'left' : 'right'}`} onClick={toggleCollapsed} />
            </div>
            <div className="header row">
                <div className="rank" />
                <div className="color-block" />
                <div className="level">Lv.</div>
                <div className="name" />
                <div className="point">Pts.</div>
            </div>
            <div className="player-rows">
                {sortedPlayers.map((player) => (
                    <div key={`player-leaderboard-${player.name}`} className={`row${player.isAlive ? '' : ' dead'}${player.isMe ? ' target' : ''}${player.isApproaching ? ' approaching' : ''}`}>
                        <div className="rank">{player.ranking}</div>
                        <div className="color-block" style={{ background: toRGBString(player.color), outline: `1px solid ${toRGBString(player.color)}` }} />
                        <div className="level"><LevelTag small level={player.level} /></div>
                        <div className="name">{player.name}</div>
                        <div className="point">{player.point}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
