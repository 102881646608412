import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useInterval } from '../hooks/useInterval';

import './VerticalTimer.scss';

export function VerticalTimer({
    interval, tick, length, width, barColor
}) {
    const [barCount, setBarCount] = useState(0);
    if (typeof tick === 'number') {
        if (barCount !== tick) {
            setBarCount(tick);
        }
    } else if (typeof interval === 'number') {
        useInterval(() => {
            setBarCount(barCount + 1);
        }, interval);
    }

    function isInfinite() {
        return (typeof length !== 'number');
    }

    function isDone() {
        return (barCount > length);
    }

    let content;
    if (!isInfinite() && !isDone()) {
        content = (
            <div
                className="finite"
                style={{
                    height: `${100 - (100 / length * barCount)}%`,
                    backgroundColor: barColor
                }}
            />
        );
    } else {
        content = (
            <div
                className="infinite"
                style={{
                    animation: `infiniteTimer ${interval / 1000}s cubic-bezier(${interval / 3000}, 0, ${interval / 3000}, ${interval / 1500}) infinite`,
                    backgroundColor: barColor
                }}
            />
        );
    }

    return (
        <div className="vertical-timer-wrapper" style={{ width: `${width}px`, height: '100%' }}>
            <div className="vertical-timer">{content}</div>
        </div>
    );
}

VerticalTimer.propTypes = {
    interval: PropTypes.number,
    tick: PropTypes.number,
    length: PropTypes.number,
    width: PropTypes.number,
    barColor: PropTypes.string
};

VerticalTimer.defaultProps = {
    interval: 1000,
    width: 50,
    barColor: '#80FFDB'
};
