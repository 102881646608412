export function traceBorder(closed, rectSize, gutterSize, borderWidth) {
    const height = closed.length;
    const width = closed[0].length;
    // Find the first non-closed point
    let firstX; let
        firstY;
    for (let j = 0; j < height; j++) {
        for (let i = 0; i < width; i++) {
            if (!closed[j][i]) {
                firstX = i;
                firstY = j;
                break;
            }
        }
        if (typeof (firstX) === 'number') {
            break;
        }
    }

    let infiniteLoopBreaker = 10000;

    let x = firstX;
    let y = firstY;
    let dx = 1;
    let dy = 0;

    const points = [];
    points.push(findPoint(x, y, 0, -1, rectSize, gutterSize, borderWidth, 'right'));

    while (infiniteLoopBreaker-- > 0) {
        // fx: forward x
        const fx = x + dx;
        const fy = y + dy;
        // flx: forward left x
        const { dx: leftTurnDx, dy: leftTurnDy } = turnDelta(dx, dy, 'left');
        const flx = fx + leftTurnDx;
        const fly = fy + leftTurnDy;

        if (fx >= 0 && fx < width && fy >= 0 && fy < height
            && flx >= 0 && flx < width && fly >= 0 && fly < height
            && !closed[fly][flx]) {
            // Turn left as front-left spot is open
            points.push(findPoint(x, y, dx, dy, rectSize, gutterSize, borderWidth, 'left'));
            x = flx;
            y = fly;
            dx = leftTurnDx;
            dy = leftTurnDy;
        } else if (fx < 0 || fx >= width || fy < 0 || fy >= height
            || closed[fy][fx]) {
            // Turn right as front spot is closed (or out-of-bound)
            points.push(findPoint(x, y, dx, dy, rectSize, gutterSize, borderWidth, 'right'));
            const { dx: rightTurnDx, dy: rightTurnDy } = turnDelta(dx, dy, 'right');
            // Not like left turn, right turn let the pointer stay at the same spot
            // to handle possible upcoming immediate left turn
            dx = rightTurnDx;
            dy = rightTurnDy;
        } else {
            x = fx;
            y = fy;
        }
        if (dx === 0 && dy === -1 && x === firstX && y === firstY) {
            break;
        }
    }
    if (infiniteLoopBreaker === 0) {
        console.error('Infinite loop detected on finding the border.');
        return [];
    }
    return points;
}

function findPoint(x, y, dx, dy, rectSize, gutterSize, borderWidth, turn) {
    // Find the center point first
    const cx = calcPos(x, rectSize, gutterSize, borderWidth) + (rectSize / 2);
    const cy = calcPos(y, rectSize, gutterSize, borderWidth) + (rectSize / 2);
    // Direction - right
    if (dx === 1 && dy === 0) {
        return {
            x: cx + (rectSize / 2) + gutterSize,
            y: cy - (rectSize / 2) - gutterSize
        };
    }
    // Direction - down
    if (dx === 0 && dy === 1) {
        return {
            x: cx + (rectSize / 2) + gutterSize,
            y: cy + (rectSize / 2) + (turn === 'right' ? gutterSize : 0)
        };
    }
    // Direction - left
    if (dx === -1 && dy === 0) {
        return {
            x: cx - (rectSize / 2) - (turn === 'right' ? gutterSize : 0),
            y: cy + (rectSize / 2) + gutterSize
        };
    }
    // Direction - up
    if (dx === 0 && dy === -1) {
        return {
            x: cx - (rectSize / 2) - gutterSize,
            y: cy - (rectSize / 2) - gutterSize
        };
    }

    return {};
}

function turnDelta(dx, dy, direction) {
    if (dy === 0) {
        if (direction === 'left') {
            return {
                dx: dy * -1,
                dy: dx * -1
            };
        }
        return {
            dx: dy,
            dy: dx
        };
    }
    if (direction === 'right') {
        return {
            dx: dy * -1,
            dy: dx * -1
        };
    }
    return {
        dx: dy,
        dy: dx
    };
}

export function calcPos(idx, rectSize, gutterSize, borderWidth) {
    return borderWidth + (rectSize * idx) + (gutterSize * (idx + 1));
}

export function directionToDiffSigns(direction) {
    if (direction === 'u') {
        return { x: 0, y: -1 };
    }
    if (direction === 'd') {
        return { x: 0, y: 1 };
    }
    if (direction === 'l') {
        return { x: -1, y: 0 };
    }
    if (direction === 'r') {
        return { x: 1, y: 0 };
    }
    return { x: 0, y: 0 };
}
