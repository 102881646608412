import { sum } from 'lodash';

const DIRECTION_MAP = { increase: 'upper', decrease: 'lower', upper: 'increase', lower: 'decrease' };

export function getAccomplishStatus(mission) {
    let isFinal;
    let isAccomplished;

    if (mission.direction) {
        if (!mission.stat.direction) {
            if (mission.direction === 'upper') {
                if (mission.stat.value > mission.stat.p80) {
                    isAccomplished = true;
                } else {
                    isAccomplished = false;
                }
            } else {
                if (mission.stat.value < mission.stat.p20) {
                    isAccomplished = true;
                } else {
                    isAccomplished = false;
                }
            }
            isFinal = false;
        } else if (DIRECTION_MAP[mission.direction] === mission.stat.direction) {
            if (mission.direction === 'upper') {
                if (mission.stat.value > mission.stat.p80) {
                    isAccomplished = true;
                    isFinal = true;
                } else {
                    isAccomplished = false;
                    isFinal = false;
                }
            } else {
                if (mission.stat.value < mission.stat.p20) {
                    isAccomplished = true;
                    isFinal = true;
                } else {
                    isAccomplished = false;
                    isFinal = false;
                }
            }
        } else {
            if (mission.direction === 'upper') {
                if (mission.stat.value > mission.stat.p80) {
                    isAccomplished = true;
                    isFinal = false;
                } else {
                    isAccomplished = false;
                    isFinal = true;
                }
            } else {
                if (mission.stat.value < mission.stat.p20) {
                    isAccomplished = true;
                    isFinal = false;
                } else {
                    isAccomplished = false;
                    isFinal = true;
                }
            }
        }
    } else {
        isAccomplished = (mission.stat.value < mission.stat.p10 || mission.stat.value > mission.stat.p90);
        isFinal = false;
    }

    return { isFinal, isAccomplished };
}

export function getAccomplishedCount(missions) {
    return sum(Object.keys(missions).map((missionName) => {
        if (getAccomplishStatus(missions[missionName]).isAccomplished) {
            return 1;
        }
        return 0;
    }));
}

// Capture the logic to calculate the class based on the achieved count
// Extremely simple at the moment
export function accomplishedCountToClass(count) {
    return count;
}