import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { setUserItemsToLocalStorage } from '../utils/user';
import { Register } from '../components/Register';
import { BetaWelcome } from '../components/BetaWelcome';

import './Register.scss';

export function RegisterPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [inviteCode, setInviteCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        const inviteCodeToValidate = searchParams.get('invite_code');
        if (!inviteCodeToValidate) {
            setInviteCode('');
            setErrorMessage('Invite code is required.');
            return;
        }
        axios.get(`${process.env.REACT_APP_GAME_REST_URI}/users/invite/check?invite_code=${inviteCodeToValidate}`).then((response) => {
            if (!response.data || response.data.isValid !== true) {
                setInviteCode('');
                setErrorMessage('Invalid invite code provided.');
                return;
            }
            if (response.data.isClaimed === true) {
                setInviteCode('');
                navigate('/');
                return;
            }
            setInviteCode(inviteCodeToValidate);
        }).catch((response) => {
            setInviteCode('');
            if (response.status === 400) {
                setErrorMessage('Invalid invite code provided.');
            } else if (response.status === 500) {
                setErrorMessage('Server error. We are very sorry and please try again later. Please.....');
            }
        });
        
    }, [searchParams, navigate]);

    const onFinishRegister = useCallback((token, userId, playerTag) => {
        setUserItemsToLocalStorage(token, userId, playerTag);
        setIsFinished(true);
    }, []);

    return (
        <div className="register-page">
            <div className="center-content">
                {inviteCode === null && <div className="message">Validating the invite code...</div>}
                {inviteCode === '' && <div className="message">{errorMessage}</div>}
                {inviteCode && !isFinished && <Register inviteCode={inviteCode} onFinish={onFinishRegister}/>}
                {isFinished && <BetaWelcome />}
            </div>
        </div>
    );
}
