import React, {
    useState, useMemo, useEffect, useContext, useCallback
} from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';

import { toRGBString, toneCodeToRGBColor } from '../utils/color';
import { toOrdinalNumber } from '../utils/string';
import { StatDisplays } from '../utils/stats';
import { UserTokenContext } from '../contexts/userTokenContext';
import { TONE_IMPORT_ADDING_INTERVAL, TONE_IMPORT_ADDING_POST_DELAY } from '../const';
import { TopNav } from './TopNav';
import { LevelTag } from './LevelTag';
import { CanvasBoard } from './CanvasBoard';
import { Button } from './Button';
import { Chart } from './Chart';
import { ToneStack } from './ToneStack';

import iconResult from '../assets/icon_result.svg';
import class0 from '../assets/class_0.svg';
import class1 from '../assets/class_1.svg';
import class2 from '../assets/stat_icons/leader_score.svg';
import iconTones from '../assets/icon_tones_result.svg';

import './GameResult.scss';
import { Tile } from './Tile';
import { GameStatCard } from './GameStatCard';
import { UserDataContext } from '../contexts/userDataContext';
import { GameDataContext } from '../contexts/GameDataContext';

const classIcons = [class0, class1, class2];

export function GameResult(props) {
    const { game: gameObject } = useContext(GameDataContext);
    const { game } = props;
    const { class: gameClass, id: gameId } = game;
    const [showResultContent, setShowResultContent] = useState(false);

    useEffect(() => {
        if (typeof gameClass === 'number' && gameClass !== 2) {
            setShowResultContent(true);
        }
    }, [gameClass]);

    if (typeof gameClass !== 'number') {
        return <div id="result" />;
    }

    return (
        <div id="result">
            {gameClass === 2 && (
                <>
                    <ConfettiLayer count={100} />
                    <GameClassSplash message="Top Class" onFinish={() => setShowResultContent(true)} />
                </>
            )}
            {showResultContent && <ResultContent gameId={gameId} gameClass={gameClass} gameType={gameObject?.type} {...props} />}
            <TopNav />
        </div>
    );
}

function ConfettiLayer({ count }) {
    const confetti = useMemo(() => {
        const pieces = [];
        for (let i = 0; i < count; i++) {
            pieces.push(
                <div key={`confetti-${i}`} className={`confetti-${i}`} />
            );
        }
        return pieces;
    }, []);

    return (
        <div className="confetti-layer">
            {confetti}
        </div>
    );
}

function GameClassSplash({
    onFinish, animationDuration = 1000, messageDuration = 750, message
}) {
    const [splashOpacity, setSplashOpacity] = useState(0);
    const { opacity } = useSpring({
        to: {
            opacity: splashOpacity
        },
        config: {
            duration: animationDuration
        },
        onRest: {
            opacity: () => setTimeout(() => {
                setSplashOpacity(0);
                onFinish();
            }, messageDuration)
        }
    });
    useEffect(() => {
        setTimeout(() => setSplashOpacity(1), 0);
    }, []);

    return (
        <animated.div className="game-class-splash" style={{ opacity }}>
            <div className="splash-content">
                <div className="message">
                    {message}
                </div>
            </div>
        </animated.div>
    );
}

function getToneCodesFromRewardTones(toneReward) {
    const codes = [];
    for (let i = 0; i < Object.keys(toneReward).length; i++) {
        const toneCode = Object.keys(toneReward)[i];
        for (let j = 0; j < toneReward[toneCode]; j++) {
            codes.push(toneCode);
        }
    }
    return codes;
}

function ResultContent({
    gameId, isEphemeral, participants, board, myPlayerTag, onPlayAgain, goToProfile, gameClass, gameType, rewards, missions, myPlayerProfile
}) {
    const { userId } = useContext(UserTokenContext);
    const me = useMemo(() => participants.find((participant) => participant.playerTag === myPlayerTag), [participants, myPlayerTag]);
    const [imported, setImported] = useState(false);

    const myReward = useMemo(() => {
        if (!userId || !rewards) {
            return null;
        }
        return rewards[userId];
    }, [userId, rewards]);

    let noToneMessage;
    if (gameClass === 0) {
        noToneMessage = 'No reward given as no mission has been accomplished.';
    } else if (!myReward?.tones || Object.keys(myReward?.tones).length === 0) {
        noToneMessage = 'No reward given as your score is not high enough';
    }
    return (
        <>
            <div className="result-content">
                <div className="result-row result-sections">
                    <div className="result-section ranking">
                        <div className="rewards">
                            <div className="player-profile-bar">
                                <div className="name">
                                    {me.playerTag}
                                </div>
                                {myPlayerProfile && (
                                    <div className="player-profile">
                                        {myPlayerProfile.level_up && (
                                            <div className="level-up-message">Level up! You are now level {myPlayerProfile.level}!!</div>
                                        )}
                                        {!myPlayerProfile.level_up && (
                                            <>
                                                <LevelTag level={myPlayerProfile.level || 1} />
                                                <Chart
                                                    data={{
                                                        total: myPlayerProfile.total_tones + myPlayerProfile.tones_to_next_level,
                                                        value: [myPlayerProfile.total_tones - Object.keys(myReward?.tones || {}).reduce((total, toneCode) => (total + myReward.tones[toneCode]), 0), Object.keys(myReward?.tones || {}).reduce((total, toneCode) => (total + myReward.tones[toneCode]), 0)]
                                                    }}
                                                    type="bar"
                                                    width={125}
                                                />
                                                <span className="next">NEXT</span>
                                                <span>{myPlayerProfile.tones_to_next_level} Tones + {myPlayerProfile.games_won_to_next_level} Wins</span>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            {gameType === 'friendly' ? (
                                <div className="received-tones">
                                    <br/>Friendly games do not offer any rewards.
                                </div>
                            ): (
                                <div className="received-tones">
                                    <img src={iconTones} width={134} />
                                    {(myReward?.tones && Object.keys(myReward?.tones).length > 0) && (
                                        <>
                                            <div className="message">✨ You Achieved <span className="tone-number">{Object.keys(myReward.tones).reduce((total, toneCode) => (total + myReward.tones[toneCode]), 0)}</span> Tones! ✨</div>
                                            <ToneRewards toneCodesWithVisibility={getToneCodesFromRewardTones(myReward.tones).map((toneCode) => ({ code: toneCode, isHidden: false }))} />
                                        </>
                                    )}
                                    {(!myReward?.tones || Object.keys(myReward?.tones).length === 0) && (
                                        <div className="message">
                                            <div className="reason">{noToneMessage}</div>
                                            <div className="encouragement">You'll get better next time!</div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th> </th>
                                    <th className="left">Lv.</th>
                                    <th className="left">Player</th>
                                    <th><i className="fa-solid fa-person-burst" /> + <i className="fa-solid fa-flag" /></th>
                                    <th>Bonus</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {participants.sort((playerA, playerB) => (playerA.ranking - playerB.ranking)).map((player, idx) => {
                                    // let prevRanking = -1;
                                    const classes = ['ranking-row'];
                                    if (player.playerTag === myPlayerTag) {
                                        classes.push('my-row');
                                    }
                                    const row = (
                                        <tr key={`ranking-${player.inGameId}-${player.ranking}`} className={classes.join(' ')}>
                                            {/* <td className="col-ranking">{prevRanking !== ranking.ranking ? ranking.ranking : ''}</td> */}
                                            <td className="col-ranking">
                                                {/* {idx + 1 === player.ranking ? player.ranking : ''} */}
                                                {player.ranking}
                                            </td>
                                            <td>
                                                <div className="marker" style={{ backgroundColor: toRGBString(player.color) }} />
                                            </td>
                                            <td>
                                                <LevelTag small level={player.playerProfile?.level || 1} />
                                            </td>
                                            <td className="col-player-tag">
                                                {player.playerTag}
                                                {player.leveledUp && <span className="level-up">Level UP!</span>}
                                            </td>
                                            <td>{player.score}</td>
                                            <td>{player.bonus}</td>
                                            <td>{player.score + player.bonus}</td>
                                        </tr>
                                    );
                                    // prevRanking = ranking.ranking;
                                    return row;
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="result-section board-wrapper">
                        <Tile>
                            <div className="result-wrapper">
                                <CanvasBoard
                                    boardWidth={board.width}
                                    boardHeight={board.height}
                                    boardWidthPx={300}
                                    boardHeightPx={300}
                                    borderWidth={4}
                                    colors={board.colors}
                                    touched={board.touched}
                                    revealed
                                />
                                <div className={`game-class class-${gameClass}`}>
                                    <img src={classIcons[gameClass]} alt="" />
                                    <div className="smaller">Achived</div>Class {gameClass}
                                </div>
                            </div>
                        </Tile>
                        <div className="divider" />
                        <div className="stats">
                            {Object.keys(missions)
                                .map((statName) => <GameStatCard statName={statName} mission={missions[statName]} size="lg" />)}
                        </div>
                    </div>
                </div>
                <div className="result-row actions">
                    {gameType !== 'friendly' && <Button label="Play again" onClick={onPlayAgain} />}
                    {!isEphemeral && <Button label="My Page" onClick={goToProfile} />}
                </div>
            </div>
            {(myReward?.tones && Object.keys(myReward?.tones).length > 0 && !imported) && (
                <ToneRewardImportPopup gameId={gameId} toneReward={myReward.tones} onClose={() => { setImported(true); }} />
            )}
        </>
    );
}

function ToneRewardImportPopup({ gameId, toneReward, onClose }) {
    const { requestUserToneReward } = useContext(UserDataContext);
    const toneCodes = useMemo(() => getToneCodesFromRewardTones(toneReward), [toneReward]);
    const [addedToneIndexes, setAddedToneIndexes] = useState([]);
    const [appearedTones, setAppearedTones] = useState([]);

    const onShuffleAndStack = useCallback(() => {
        setAddedToneIndexes(toneCodes.map((tc, idx) => (idx)));
        setTimeout(() => {
            onClose();
        }, (TONE_IMPORT_ADDING_INTERVAL * toneCodes.length) + TONE_IMPORT_ADDING_POST_DELAY);
    }, [toneCodes]);

    const isAllRewardAdded = useMemo(() => (toneCodes.length === addedToneIndexes.length), [toneCodes, addedToneIndexes]);

    const onItemAppearedOnStack = useCallback((appearedTone) => {
        if (appearedTones.length === toneCodes.length - 1) {
            requestUserToneReward(gameId, [...appearedTones, appearedTone]);
        } else {
            setAppearedTones((oldItems) => ([...oldItems, appearedTone]));
        }
    }, [toneCodes, appearedTones, setAppearedTones]);

    return (
        <div className="import-tone-stack">
            <div className="received-tones">
                <img src={iconTones} width={134} />
                <div className="message">✨ You Achieved <span className="tone-number">{Object.keys(toneReward).reduce((total, toneCode) => (total + toneReward[toneCode]), 0)}</span> Tones! ✨</div>
                <ToneRewards
                    toneCodesWithVisibility={toneCodes.map((toneCode, idx) => ({ code: toneCode, isHidden: (new Set(addedToneIndexes)).has(idx) }))}
                    onClickTone={(clickedIdx) => {
                        if (addedToneIndexes.length === toneCodes.length - 1) {
                            setTimeout(() => {
                                onClose();
                            }, TONE_IMPORT_ADDING_POST_DELAY);
                        }
                        setAddedToneIndexes([...addedToneIndexes, clickedIdx]);
                    }}
                />
                <div className="actions">
                    {!isAllRewardAdded && <Button label="Shuffle and Stack" onClick={onShuffleAndStack} />}
                    {isAllRewardAdded && <div>Stacking Rewards...</div>}
                </div>
            </div>
            <ToneStack added={(appearedTones.length !== toneCodes.length) ? addedToneIndexes.map((idx) => toneCodes[idx]) : []} onItemAppeared={onItemAppearedOnStack} />
        </div>
    );
}

function ToneRewards({ toneCodesWithVisibility, onClickTone }) {
    const tonePieces = useMemo(() => toneCodesWithVisibility.map(({ code, isHidden }, idx) => {
        const colorRgbString = toRGBString(toneCodeToRGBColor(code));
        if (isHidden) {
            return <div className="reward-tone-placeholder" />;
        }
        return (
            <div
                key={`reward-tone-${idx}`}
                className="reward-tone"
                style={{ backgroundColor: colorRgbString }}
                onClick={() => {
                    if (onClickTone) {
                        onClickTone(idx);
                    }
                }}
            />
        );
    }), [toneCodesWithVisibility]);

    if (toneCodesWithVisibility.length === 0) {
        return (
            <div>None</div>
        );
    }

    return (
        <div>
            <div className="reward-tones">
                {tonePieces}
            </div>
        </div>
    );
}
