import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export function useD3Ref(renderFunction, dependencies) {
    const d3Ref = useRef();

    useEffect(() => {
        renderFunction(d3.select(d3Ref.current));
    }, dependencies);

    return d3Ref;
}
