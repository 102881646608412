import React, {
    useEffect, useState, useRef, useMemo
} from 'react';
import './GameStatusMessage.scss';

/**
 * GameStatusMessage, most importantly, has two distinct layers of messages.
 * 1. Status message
 * Status message is a message that is displayed constantly as a prompt for user behavior
 * whether it is to wait or perform a certain action.
 * The message does not have a definite duration and gets overriden by the event messages.
 * 2. Event message
 * Event message is provided to the component through a message queue
 * and dequeued one by one to be displayed for the given duration.
 *
 * @param {string} statusMessage Status message that shows up when no active event.
 * @param {string} incomingQueue Array with incoming events
 * @returns
 *
 *
 */
export function GameStatusMessage({ statusMessage, incomingQueue }) {
    const isAvailable = useRef(true);
    const [eventQueue, setEventQueue] = useState([]);
    const [currentEventIndex, setCurrentEventIndex] = useState(-1);
    const [popUp, setPopUp] = useState(false);

    // Move new incoming events into the internal queue
    useEffect(() => {
        if (!incomingQueue) {
            return;
        }
        const incomingEvents = incomingQueue.splice(0);
        setEventQueue((oldQueue) => ([...oldQueue, ...incomingEvents]));
        if (currentEventIndex === -1 && isAvailable.current) {
            setCurrentEventIndex((oldValue) => (oldValue + 1));
        }
    }, [incomingQueue]);

    // Proceed with the messages in the queue one by one
    useEffect(() => {
        if (currentEventIndex === -1) {
            return;
        }
        if (currentEventIndex >= eventQueue.length) {
            isAvailable.current = true;
            return;
        }
        isAvailable.current = false;
        if (eventQueue[currentEventIndex].popUp) {
            setPopUp(true);
        }
        setTimeout(() => {
            setCurrentEventIndex(currentEventIndex + 1);
            if (eventQueue[currentEventIndex].popUp) {
                setPopUp(false);
            }
        }, eventQueue[currentEventIndex].durationInSeconds * 1000);
    }, [eventQueue, currentEventIndex, setCurrentEventIndex]);

    const messageType = useMemo(() => {
        if (currentEventIndex < 0 || currentEventIndex >= eventQueue.length) {
            return 'default';
        }
        if (!eventQueue[currentEventIndex].type) {
            return 'event';
        }
        return eventQueue[currentEventIndex].type;
    }, [eventQueue, currentEventIndex]);

    const className = useMemo(() => {
        const classNames = ['game-status-msg', messageType];
        if (popUp) {
            classNames.push('popup');
        }
        return classNames.join(' ');
    }, [messageType, popUp]);

    const eventMessage = useMemo(() => {
        if (currentEventIndex >= 0 && currentEventIndex < eventQueue.length) {
            return eventQueue[currentEventIndex].message;
        }
    }, [eventQueue, currentEventIndex]);

    return (
        <div className={className}>
            {eventMessage && (
                <div key={`event-message-${eventMessage}`} className="message">
                    <span>{eventMessage}</span><span>!</span>
                </div>
            )}
            {!eventMessage && (
                <div key="status-message" className="status-message message">
                    <span>{statusMessage}</span><span className="dots">...</span>
                </div>
            )}
        </div>
    );
}

// return <div className={`game-status-msg ${type}`}>
//         {type === 'default' && 'Choose a card...'}
//         {type === 'event' && 'Knocked out: '}
//         {type !== 'default' && players && <span className='player-names'>{players?.join(', ')}</span>}
//         {type === 'alert' && ` ${players?.length > 1 ? 'are' : 'is'} approaching`}
//     </div>
