import React from 'react';

import { Button } from './Button';
import { Tile } from './Tile';

import './Confirmation.scss';

export function Confirmation({ message, onConfirm, onCancel }) {
    return (
        <>
            <div className="confirmation">
                <Tile>
                    <div className="confirmation-inner">
                        <div className="confirmation-message">{message}</div>
                        <div className="confirmation-actions">
                            <Button label="Confirm" onClick={onConfirm} />
                            <Button label="Cancel" onClick={onCancel} transparent />
                        </div>
                    </div>
                </Tile>
            </div>
            <div className="confirmation-backdrop" />
        </>
    );
}
