import React from 'react';

import './Tile.scss';

export function Tile({ children }) {
    return (
        <div className="tile">
            {children}
        </div>
    );
}
