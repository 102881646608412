import { useEffect, useRef } from 'react';

export function useInterval(callback, interval) {
    const callbackRef = useRef(callback);
    useEffect(() => {
        if (callback) {
            callbackRef.current = callback;
        }
    }, [callback]);

    useEffect(() => {
        if (interval) {
            const intervalId = setInterval(() => {
                if (typeof callbackRef.current === 'function') {
                    callbackRef.current();
                }
            }, interval);

            return () => clearInterval(intervalId);
        }
    }, [interval]);
}
