import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { SignIn } from '../components/SignIn';
import { DarkBackground } from '../components/DarkBackground';

import './SignIn.scss';

export function SignInPage() {
    const [searchParams] = useSearchParams();
    return (
        <div className="sign-in-page">
            <div className="center-content">
                <SignIn to={`/${searchParams.get('to')}`} />
            </div>
            <DarkBackground gradation squares />
        </div>
    );
}
