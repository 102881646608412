import React, {
    useState, useMemo, useEffect, createRef
} from 'react';

import './Deck.scss';

const getDirection = (dir) => {
    switch (dir) {
    case 'l': return 'left';
    case 'r': return 'right';
    case 'u': return 'up';
    case 'd': return 'down';
    }
};
export function Deck({ deck, onCardSelect, preventDefaultSelect, widgetWidth }) {
    const [selectedCardIdx, setSelectedCardIdx] = useState(null);
    const [cardSpots, setCardSpots] = useState([]);
    const [cardSpotRefs, setCardSpotRefs] = useState([]);
    const [cards, setCards] = useState([]);
    const [separators, setSeparators] = useState({});

    const cardSelected = useMemo(() => (cardIdx) => {
        if (typeof (cardIdx) === 'number' && onCardSelect) {
            onCardSelect(cardIdx);
            setSelectedCardIdx(cardIdx);
        }
    }, [onCardSelect]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            const { keyCode } = e;
            if (keyCode >= 49 && keyCode < 49 + deck.length) {
                cardSelected(keyCode - 49);
            } else if (keyCode >= 49 + deck.length) {
                cardSelected(deck.length - 1);
            } else if (keyCode >= 37 && keyCode <= 40) {
                const selectedDirection = ['l', 'u', 'r', 'd'][keyCode - 37];
                let firstIdx = -1;
                for (let i = 0; i < deck.length; i++) {
                    if (deck[i].direction === selectedDirection) {
                        firstIdx = i;
                        break;
                    }
                }
                if (firstIdx !== -1) {
                    cardSelected(firstIdx);
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [deck, cardSelected]);

    useEffect(() => {
        if (preventDefaultSelect) {
            setSelectedCardIdx(null);
            return;
        }
        if (deck.length > 0) {
            const randomlySelected = parseInt(Math.random() * deck.length);
            cardSelected(randomlySelected);
            if (deck.length === 10) {
                setCards([]);
            }
        }
    }, [cardSelected, deck, preventDefaultSelect]);

    useEffect(() => {
        if (deck.length > 0) {
            const allCardSpots = {
                u: [],
                l: [],
                r: [],
                d: []
            };
            const allCardSpotRefs = {};
            for (let i = 0; i < deck.length; i++) {
                const spotRef = createRef();
                allCardSpots[deck[i].direction].push(
                    <div
                        key={`card-spot-${deck[i].originalIdx}`}
                        className="card-spot"
                        ref={spotRef}
                    />
                );
                allCardSpotRefs[deck[i].originalIdx] = spotRef;
            }
            setCardSpots(allCardSpots);
            setCardSpotRefs(allCardSpotRefs);

            // Decide to place separators between directions
            let separated = (allCardSpots.l.length === 0);
            const currentSeparators = {};
            ['u', 'd', 'r'].forEach((d) => {
                if (allCardSpots[d].length === 0) {
                    return;
                }
                if (!separated) {
                    currentSeparators[d] = true;
                }
                separated = false;
            });
            setSeparators(currentSeparators);
        }
    }, [deck, selectedCardIdx]);

    useEffect(() => {
        if (Object.keys(cardSpotRefs).length > 0 && Object.keys(cardSpotRefs).length === deck.length && (!widgetWidth || widgetWidth > 0)) {
            const allCards = [];
            for (let i = 0; i < deck.length; i++) {
                if (!cardSpotRefs[deck[i].originalIdx].current) {
                    return;
                }
                allCards.push(
                    <div
                        key={`card-${deck[i].originalIdx}-${deck[i].batchId || ''}`}
                        className={`${selectedCardIdx === i ? 'card selected' : 'card'} direction-${deck[i].direction}`}
                        style={{
                            top: `${cardSpotRefs[deck[i].originalIdx].current.offsetTop}px`,
                            left: `${cardSpotRefs[deck[i].originalIdx].current.offsetLeft}px`
                        }}
                        onClick={() => cardSelected(i)}
                    >
                        <div className={`card-direction direction-${deck[i].direction}`}><i className={`bi bi-arrow-${getDirection(deck[i].direction)}-short`} /></div>
                        <div className="card-header">
                            <div className="card-count">
                                <i className="bi bi-stack" />
                                {deck[i].count}
                            </div>
                        </div>
                        <div className="card-num">
                            {deck[i].cardNum}
                        </div>
                    </div>
                );
            }
            setCards(allCards);
        }
    }, [deck, cardSelected, selectedCardIdx, cardSpotRefs, widgetWidth]);

    return (
        <div className="card-deck">
            <div className="card-spot-container">
                {cardSpots.l}
                {separators.u && <div className="separator" />}
                {cardSpots.u}
                {separators.d && <div className="separator" />}
                {cardSpots.d}
                {separators.r && <div className="separator" />}
                {cardSpots.r}
            </div>
            <div className="card-container">
                {cards}
            </div>
        </div>
    );
}
