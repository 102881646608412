import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import './Button.scss';

export function Button({
    label, icon, inverted, transparent, onClick, disabled, disableMessage, contentType
}) {
    const classes = ['button'];
    if (inverted) {
        classes.push('inverted');
    }
    if (transparent) {
        classes.push('transparent');
    }
    if (disabled) {
        classes.push('disabled');
    }
    if (contentType === 'iconOnly') {
        classes.push('icon-only');
    }
    if (contentType === 'textOnly') {
        classes.push('text-only');
    }
    if (contentType === 'iconOnlyAccent') {
        classes.push('icon-only');
        classes.push('accent');
    }
    return (
        <>
            <button
                type="button"
                className={classes.join(' ')}
                onClick={onClick}
                data-tip={disabled && disableMessage}
                data-for={disabled && disableMessage ? 'tip-disabled-button' : ''}
            >
                {(contentType === 'iconOnly' || contentType === 'iconOnlyAccent') ? <i className={`bi bi-${icon}`} /> : label}
            </button>
            {disabled && disableMessage && (
                <ReactTooltip id="tip-disabled-button" place="bottom" multiline effect="solid">
                    {disableMessage}
                </ReactTooltip>
            )}
        </>
    );
}

Button.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    inverted: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

Button.defaultProps = {
    onClick: undefined
};
