import { sum } from 'lodash';

// Capture the logic to calculate the factor based on the game class
// Extremely simple at the moment,
export function classToFactor(gameClass) {
    return gameClass;
}

export function getAchievedCount(missions) {
    return sum(Object.keys(missions).map((statName) => {
        const statInfo = missions[statName];
        if (statInfo && (statInfo.value < statInfo.p10 || statInfo.value > statInfo.p90)) {
            return 1;
        }
        return 0;
    }));
}

// Capture the logic to calculate the class based on the achieved count
// Extremely simple at the moment
export function achievedCountToClass(count) {
    return count;
}

export function toPlayerListItem(player, me, playerProfiles) {
    const {
        id, name, color, colorMode, isAlive, isMe, ranking, bonus, score, x, y
    } = player;

    const isApproaching = (
        me
            && me.isAlive
            && id !== me.id
            && isAlive
            && typeof x === 'number'
            && typeof y === 'number'
            && Math.abs(x - me.x) <= 1
            && Math.abs(y - me.y) <= 1
    );

    return {
        id,
        name,
        color,
        colorMode,
        isAlive,
        isMe,
        isApproaching,
        ranking,
        level: playerProfiles?.[id]?.level,
        point: bonus + score,
        isVisible: x !== null && y !== null
    };
}

export function sortPlayersByPointDesc(playerA, playerB) {
    if (playerA.ranking !== playerB.ranking) {
        return playerA.ranking - playerB.ranking;
    }
    return playerB.point - playerA.point;
}
