import React from 'react';
import {
    Stage, Layer, Rect, Line, Text, Circle, Group
} from 'react-konva';
import { toFixedTwo } from '../utils/stats';
import { bezierCurveThrough } from '../utils/draw';

import './DistributionChart.scss';

const PADDING_H = 15;
const PADDING_V = 5;
const TEXT_HEIGHT = 10;
const AXIS_THICKNESS = 1;
const POINTER_RADIUS = 7;

const STROKE_COLOR = '#c8c9d7';
const POINTER_COLOR = '#e55f59';

export function DistributionChart({
    buckets, currentValue, width, height, formatter, range, hideLabel
}) {
    let extraSpaceLeft = 0;
    let extraSpaceRight = 0;
    let barWidth = (width - (PADDING_H * 2) - (buckets.length - 1)) / buckets.length;

    const bucketMin = buckets[0].min;
    const bucketMax = buckets[buckets.length - 1].max;
    let rangeMin = bucketMin;
    let rangeMax = bucketMax;
    const bucketSize = buckets[0].max - buckets[0].min;
    if (currentValue < bucketMin) {
        let numExtraBuckets = (bucketMin - currentValue) / bucketSize;
        if (numExtraBuckets > parseInt(numExtraBuckets)) {
            numExtraBuckets = parseInt(numExtraBuckets) + 1;
        }
        rangeMin = bucketMin - (bucketSize * numExtraBuckets);
        barWidth = (width - (PADDING_H * 2) - (buckets.length + numExtraBuckets - 1)) / (buckets.length + numExtraBuckets);
        extraSpaceLeft = numExtraBuckets * barWidth;
    } else if (currentValue > bucketMax) {
        let numExtraBuckets = (currentValue - bucketMax) / bucketSize;
        if (numExtraBuckets > parseInt(numExtraBuckets)) {
            numExtraBuckets = parseInt(numExtraBuckets) + 1;
        }
        rangeMax = bucketMax + (bucketSize * numExtraBuckets);
        barWidth = (width - (PADDING_H * 2) - (buckets.length + numExtraBuckets - 1)) / (buckets.length + numExtraBuckets);
        extraSpaceRight = numExtraBuckets * barWidth;
    }

    const labelFormatter = formatter ?? toFixedTwo;
    const labelMin = range && !!range.p10 ? range.p10 : bucketMin;
    const labelMax = range && !!range.p90 ? range.p90 : bucketMax;
    return (
        <div className="distribution-chart">
            <Stage width={width} height={height}>
                <Layer>
                    <Group clipFunc={getClipFunc(buckets, barWidth, height, extraSpaceLeft, range)}>
                        <Rect
                            width={width}
                            height={height}
                            fillLinearGradientStartPoint={{ y: 0 }}
                            fillLinearGradientEndPoint={{ y: height }}
                            fillLinearGradientColorStops={[0, '#fffa', 0.8, '#4454']}
                        />
                        {drawBars(buckets, barWidth, height, extraSpaceLeft)}
                    </Group>
                    {drawXAxis(width, height)}
                </Layer>
                {!hideLabel
                    && (
                        <Layer>
                            {drawLabels(labelFormatter(labelMin), labelFormatter(labelMax), labelMin, labelMax, rangeMin, rangeMax, width, height)}
                        </Layer>
                    )}
                {!!currentValue
                    && (
                        <Layer>
                            {drawCurrentValuePointer(currentValue, rangeMin, rangeMax, width, height)}
                        </Layer>
                    )}
            </Stage>
        </div>
    );
}

function drawCurrentValuePointer(value, rangeMin, rangeMax, width, height) {
    const relativeValue = (value - rangeMin) / (rangeMax - rangeMin);
    const x = PADDING_H + ((width - (PADDING_H * 2)) * relativeValue);
    return (
        <Circle
            x={x}
            y={height - PADDING_V - TEXT_HEIGHT}
            radius={POINTER_RADIUS}
            fill={POINTER_COLOR}
        />
    );
}

function drawLabels(min, max, minValue, maxValue, rangeMin, rangeMax, width, height) {
    const relativeMinValue = (minValue - rangeMin) / (rangeMax - rangeMin);
    const minX = PADDING_H + ((width - (PADDING_H * 2)) * relativeMinValue);
    const relativeMaxValue = (maxValue - rangeMin) / (rangeMax - rangeMin);
    const maxX = PADDING_H + ((width - (PADDING_H * 2)) * relativeMaxValue);

    const FONT_SIZE = 8;
    return (
        <>
            <Text
                text={min}
                fontSize={FONT_SIZE}
                width={30}
                align="center"
                x={minX - 15}
                y={height - PADDING_V - TEXT_HEIGHT + AXIS_THICKNESS + 2}
            />
            <Text
                text={max}
                fontSize={FONT_SIZE}
                width={30}
                align="center"
                x={maxX - 15}
                y={height - PADDING_V - TEXT_HEIGHT + AXIS_THICKNESS + 2}
            />
        </>
    );
}

function drawBars(buckets, barWidth, height, xOffset) {
    const barMaxHeight = (height - (PADDING_V * 2) - TEXT_HEIGHT - AXIS_THICKNESS);
    const bars = buckets.map((bucket, index) => (
        <Rect
            key={`bar-${bucket.min}-${bucket.max}`}
            x={PADDING_H + (barWidth * index) + index + xOffset}
            y={PADDING_V - 1}
            width={barWidth}
            height={barMaxHeight + 2}
            stroke={STROKE_COLOR}
            strokeWidth={1}
        />
    ));

    return bars;
}

function getClipFunc(buckets, barWidth, height, xOffset, range) {
    const maxDensity = Math.max(...buckets.map((bucket) => bucket.density));
    const minDensity = Math.min(...buckets.map((bucket) => bucket.density));
    const barMaxHeight = (height - (PADDING_V * 2) - TEXT_HEIGHT - AXIS_THICKNESS);
    const BASE = 0.2;
    const relativeFactor = (1 - BASE) / (maxDensity - minDensity);
    const points = [xOffset - barWidth, barMaxHeight];
    const ps = [[xOffset - barWidth + PADDING_H, barMaxHeight + PADDING_V]];
    buckets.forEach((bucket, index) => {
        const relativeDensity = ((bucket.density - minDensity) * relativeFactor) + BASE;
        points.push((barWidth * index) + index + xOffset + (barWidth / 2));
        points.push((barMaxHeight * (1 - relativeDensity)));
        ps.push([
            (barWidth * index) + index + xOffset + (barWidth / 2) + PADDING_H,
            (barMaxHeight * (1 - relativeDensity)) + PADDING_V
        ]);
    });
    points.push((barWidth * buckets.length) + buckets.length + xOffset + barWidth);
    points.push(barMaxHeight);
    ps.push([
        (barWidth * buckets.length) + buckets.length + xOffset + barWidth + PADDING_H,
        barMaxHeight + PADDING_V
    ]);

    return (context) => {
        bezierCurveThrough(context, ps, 0.3);
        context.closePath();
    };
}

function drawXAxis(width, height) {
    return (
        <Line
            points={[0, height - TEXT_HEIGHT - PADDING_V, width, height - TEXT_HEIGHT - PADDING_V]}
            stroke={STROKE_COLOR}
            strokeWidth={AXIS_THICKNESS}
        />
    );
}
