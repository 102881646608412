import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { ResetPassword } from '../components/ResetPassword';

import './Register.scss';

export function ResetPasswordPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [recoveryCode, setRecoveryCode] = useState<String>('');
    const [errorMessage, setErrorMessage] = useState<String>('');

    useEffect(() => {
        const code = searchParams.get('recovery_code');
        if (code) {
            setRecoveryCode(code);
        } else {
            setErrorMessage('Recovery code is needed.');
        }
    }, [searchParams]);

    const onFinishRegister = useCallback(() => {
        navigate('/');
    }, []);

    return (
        <div className="register-page">
            <div className="center-content">
                {recoveryCode === '' && <div className="message">{errorMessage}</div>}
                {recoveryCode && <ResetPassword recoveryCode={recoveryCode} onFinish={onFinishRegister}/>}
            </div>
        </div>
    );
}
