import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Button.scss';

export function NavButton({
    label, icon, inverted, transparent, to, disabled, disableMessage, contentType
}) {
    const classes = ['button'];
    if (inverted) {
        classes.push('inverted');
    }
    if (transparent) {
        classes.push('transparent');
    }
    if (disabled) {
        classes.push('disabled');
    }
    if (contentType === 'iconOnly') {
        classes.push('icon-only');
    }
    if (contentType === 'textOnly') {
        classes.push('text-only');
    }
    if (contentType === 'iconOnlyAccent') {
        classes.push('accent');
    }
    return (
        <>
            <Link
                to={to}
                className={classes.join(' ')}
                data-tip={disabled && disableMessage}
                data-for={disabled && disableMessage ? 'tip-disabled-button' : ''}
            >
                {contentType === 'iconOnly' ? <i className={`bi bi-${icon}`} /> : label}
            </Link>
            {disabled && disableMessage && (
                <ReactTooltip id="tip-disabled-button" place="bottom" multiline effect="solid">
                    {disableMessage}
                </ReactTooltip>
            )}
        </>
    );
}

NavButton.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    size: PropTypes.string,
    inverted: PropTypes.bool,
    disabled: PropTypes.bool
};

NavButton.defaultProps = {
    size: 'medium',
    inverted: false,
    disabled: false
};
