import React, { useState, useEffect } from 'react';

import { LevelTag } from '../components/LevelTag';
import iconTones from '../assets/icon_tones.svg';
import iconTurns from '../assets/icon_turns.svg';

import './BetaLeaderboard.scss';

// TODO: Show "you" in the leaderboard
// If within top 5, just highlight
// If not, add to the bottom

function TeamRanking({ tonesByTeam, myTeamId }) {
    return (
        <div className="beta-leaderboard-section team-ranking">
            <div className="title"><img src={iconTurns} width={24} />Team Ranking</div>
            <table className="teams">
                <thead>
                    <tr>
                        <th> </th>
                        <th className="left">Team</th>
                        <th className="left">Num. of Players</th>
                        <th>Unique Colors</th>
                        <th>Tones</th>
                    </tr>
                </thead>
                <tbody>
                    {tonesByTeam.map((team, idx) => {
                        return (
                            <tr key={`team-${team.team_name}`} className={`team${team.team_id === myTeamId ? ' my' : ''}`}>
                                <td className="col-ranking">{idx + 1}</td>
                                <td className="col-player-tag">Team {team.team_name}</td>
                                <td>{team.users.length}</td>
                                <td>{team.tonesByColor ? Object.keys(team.tonesByColor).length : 0}</td>
                                <td>{team.tones}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export function NetWorthStandings({ netWorthStandings, myId, myPlayerTag, myPlayerProfile }) {
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const newRows = [];
        let wasIAdded = false;
        netWorthStandings.slice(0, 5).forEach((leader, idx) => {
            if (leader.id === myId) {
                wasIAdded = true;
            }
            newRows.push(
                <tr key={`leader-${leader.player_tag}`} className={`leader${leader.id === myId ? ' my' : ''}`}>
                    <td className="col-ranking">{idx + 1}</td>
                    <td><LevelTag small level={leader.player_profile.level} /></td>
                    <td className="col-player-tag">{leader.player_tag}</td>
                    <td>{leader.player_profile.games_played}</td>
                    <td>{leader.player_profile.total_tones}</td>
                </tr>
            );
        });
        if (!wasIAdded && myId && myPlayerTag && myPlayerProfile) {
            newRows.push(
                <tr key={`leader-${myPlayerTag}`} className="leader my unranked">
                    <td className="col-ranking">&#8734;</td>
                    <td><LevelTag small level={myPlayerProfile.level} /></td>
                    <td className="col-player-tag">{myPlayerTag}</td>
                    <td>{myPlayerProfile.games_played}</td>
                    <td>{myPlayerProfile.total_tones}</td>
                </tr>
            );
        }
        setRows(newRows);
    }, [netWorthStandings, myId, myPlayerTag, myPlayerProfile]);
    return (
        <div className="beta-leaderboard-section net-worth">
            <div className="title"><img src={iconTones} width={24} />Tone Leaders</div>
            <table className="leaders">
                <thead>
                    <tr>
                        <th> </th>
                        <th className="left">Lv.</th>
                        <th className="left">Player</th>
                        <th>Game Played</th>
                        <th>Tones</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    );
}

export function BetaLeaderboard({ netWorthStandings, tonesByTeam, myTeamId, myId, myPlayerTag, myPlayerProfile }) {
    return (
        <div className="beta-leaderboard">
            <TeamRanking myTeamId={myTeamId} tonesByTeam={tonesByTeam} />
            <NetWorthStandings netWorthStandings={netWorthStandings} myId={myId} myPlayerTag={myPlayerTag} myPlayerProfile={myPlayerProfile} />
        </div>
    );
}
